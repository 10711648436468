import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { Evento, EventoInscricao } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class EventoService {

  constructor(private http: HttpClient) { }


  buscarPorParametro(param: string, id: number = 0,Ativo: boolean, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    var FiltroEvento = {
     Nome: param,
     CidadeId: id,
     Ativo: Ativo,
     Pagina: pagina
    }

    return this.http.post<any>(`${GERENCIAMENTO}/api/evento/lista`, FiltroEvento)
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/Evento/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/Evento/${id}`);
  }

  salvar(evento: Evento): Observable<any> {
    if (evento.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/Evento/editarEvento/${evento.Id}`, evento);
    }
    else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/Evento/salvarEvento`, evento);
    }
  }

  uploadImagemPrincipal(arquivo: any, id: number): Observable<any> {
    const url = `${GERENCIAMENTO}/api/evento/imagemPrincipal/${id}`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);
    return this.http.post(url, formData, { headers: headers });
  }
  
  removerEventoImagem(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/Evento/imagem/remover/${id}`);
  }


  //EventoImagens
  uploadImagem(arquivo: any, id: number): Observable<any> {
    const url = `${GERENCIAMENTO}/api/evento/imagem/${id}`;

    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);

    return this.http.post(url, formData, { headers: headers });
  }

  //Inscrições
  removerInscricao(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/Evento/removerInscricao/${id}`);
  }

  salvarInscricao(eventoInscricao: EventoInscricao): Observable<any> {
    if (eventoInscricao.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/Evento/Inscricao/${eventoInscricao.Id}`, eventoInscricao);
    }
    else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/Evento/Inscricao/`, eventoInscricao);
    }
  }

  buscarInscricaoPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/Evento/Inscricao/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  removerInscricaoRespostas(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/Evento/removerInscricaoRepostas/${id}`);
  }
}
