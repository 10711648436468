import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { LoginService } from './login.service';


import { Estado } from '../model';

import { API, ITENSPORPAGINA } from '../app.config';
import { CLIENT_ID } from '../app.config';

@Injectable()
export class EstadoService{
  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

      if (param.length > 0){
        return this.http.get<any>( `${API}/api/Estado/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${API}/api/Estado?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${API}/api/Estado/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${API}/api/Estado/${id}`);
    }

    salvar(estado: Estado): Observable<any>{
      if (estado.Id > 0){
        return this.http.put<any>( `${API}/api/Estado/${estado.Id}`, estado);
      }else{
        return this.http.post<any>( `${API}/api/Estado/`, estado);
      }

    }


  }
