import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { Pais } from '../model';
import { API, ITENSPORPAGINA } from '../app.config';

@Injectable()
export class PaisService{
  constructor(
    private http: HttpClient  ){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

      if (param.length > 0){
        return this.http.get<any>( `${API}/api/Pais/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${API}/api/Pais?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${API}/api/Pais/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${API}/api/Pais/${id}`);
    }

    salvar(pais: Pais): Observable<any>{
      if (pais.Id > 0){
        return this.http.put<any>( `${API}/api/Pais/${pais.Id}`, pais);
      }else{
        return this.http.post<any>( `${API}/api/Pais/`, pais);
      }

    }


  }
