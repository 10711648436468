
     <nb-layout>
       <nb-layout-column [class.arkbeds-padding]="ARKBEDS_FUNDO" [class.frt-fundo]="FRT_FUNDO" [class.frt-py-fundo]="FRT_PY_FUNDO">
        <a href="https://whitelabel.geniusws.com.br/" target="_blank" class="link-imagem"></a>
        <nb-card   [class.arkbeds-banner]="ARKBEDS_FUNDO"  [class.transparent-fundo]="FRT_FUNDO || FRT_PY_FUNDO">
          <nb-card-header [class.border]="ARKBEDS_FUNDO" [class.sem-aparecer]="FRT_FUNDO || ARKBEDS_FUNDO || FRT_PY_FUNDO">
            <nav class="navigation">
              <a href="#" (click)="back()" class="link" aria-label="Back">
                <i class="icon nb-arrow-thin-left"></i>
              </a>
            </nav>
          </nb-card-header>
          <nb-card-body class="row m-0 col-md-12 justify-content-center align-items-sm-center"[class.arkbeds-padding]="ARKBEDS_FUNDO">
            <router-outlet></router-outlet>
          </nb-card-body>
        </nb-card>
       </nb-layout-column>
     </nb-layout>
      <!-- <nb-layout>
       <nb-layout-column [class.arkbeds-padding]="ARKBEDS_FUNDO" [class.frt-fundo]="FRT_FUNDO" [class.frt-py-fundo]="FRT_PY_FUNDO">
        <nb-card   [class.arkbeds-banner]="ARKBEDS_FUNDO"  [class.transparent-fundo]="FRT_FUNDO || FRT_PY_FUNDO">
          <nb-card-header [class.border]="ARKBEDS_FUNDO" [class.sem-aparecer]="FRT_FUNDO || ARKBEDS_FUNDO || FRT_PY_FUNDO">
            <nav class="navigation">
              <a href="#" (click)="back()" class="link" aria-label="Back">
                <i class="icon nb-arrow-thin-left"></i>
              </a>
            </nav>
          </nb-card-header>
          <nb-card-body class="row m-0 col-md-12 justify-content-center align-items-sm-center"[class.arkbeds-padding]="ARKBEDS_FUNDO">
            <router-outlet></router-outlet>
          </nb-card-body>
        </nb-card>
       </nb-layout-column>
     </nb-layout> -->
   