import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { Produto } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class ProdutoService {
    constructor(private http: HttpClient) { }

    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        if (param.length > 0) {
            return this.http.get<any>(`${GERENCIAMENTO}/api/produto/lista/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        } else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/produto/lista?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }


    buscarProdutoTipo(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        if (param.length > 0) {
            return this.http.get<any>(`${GERENCIAMENTO}/api/produtotipo/lista/${param.trim()}`);
        } else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/produtotipo/lista/?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
        }
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}/api/produto/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/produto/${id}`);
    }

    salvar(Produto: Produto): Observable<any> {
        if (Produto.Id > 0) {
            return this.http.put<any>(`${GERENCIAMENTO}/api/produto/${Produto.Id}`, Produto);
        }
        else {
            return this.http.post<any>(`${GERENCIAMENTO}/api/produto/`, Produto);
        }

    }

    uploadImagem(arquivo: any, id: number): Observable<any> {

        const url = `${GERENCIAMENTO}/api/produto/imagem/${id}/`;

        const headers = new HttpHeaders().append('noloader', 'noloader');
        const formData = new FormData();
        formData.append('Arquivo', arquivo);

        return this.http.post(url, formData, { headers: headers });

    }

    removerImagem(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/produto/imagem/remover/${id}`);
    }
}
