import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { OfflineApartamento } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class OfflineApartamentoService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{

      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineapartamento/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineapartamento?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineapartamento/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlineapartamento/${id}`);
    }

    salvar(apartamentoOffline: OfflineApartamento): Observable<any>{
      if (apartamentoOffline.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/offlineapartamento/${apartamentoOffline.Id}`, apartamentoOffline);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/offlineapartamento/`, apartamentoOffline);
      }

    }

  }
