import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



// import { } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA, API_RELATORIOS } from '../../app.config';
import { ReservaPagamento } from '../../model';
var geolocalizacao: any = false;

@Injectable()
export class ContratosService{

  constructor(private http: HttpClient){
  }
    ngOnInit() {
  }
  buscarTodas(): Observable<any>{
    let headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>( `${GERENCIAMENTO}/api/Contrato?Pagina=1&ItensPorPagina=100`, {headers: headers});
  }
  buscarPorParametro(pagina: Number, reservaId : string,status : string,cpfassinatura : string,datainicial: any = "", datafinal: any = "",dataassinaturainicial: any = "", dataassinaturafinal: any = "",tipoPagamento: string, assinado:string,itensPagina: number = 0): Observable<any>{
    let headers = new HttpHeaders().set('noloader', 't');
    itensPagina = itensPagina == 0 ? ITENSPORPAGINA : itensPagina;
    return this.http.get<any>( `${GERENCIAMENTO}/api/contrato?Pagina=${pagina}&ItensPorPagina=${itensPagina}&reservaId=${reservaId}&status=${status}&cpfassinatura=${cpfassinatura}&DataInicial=${datainicial}&DataFinal=${datafinal}&DataAssinaturaInicial=${dataassinaturainicial}&DataAssinaturaFinal=${dataassinaturafinal}&TipoPagamento=${tipoPagamento}&Assinado=${assinado}`, {headers: headers});
  }
  alterarStatus(status, contrato, justificativa):Observable<any>{
    return this.http.put<any>(`${GERENCIAMENTO}/api/contrato/${contrato.Id}/aprovar-contrato`, {status: status, justificativa: justificativa});
  }
  buscarPorId(id: number): Observable<any>{
    return this.http.get<any>( `${GERENCIAMENTO}/api/Contrato/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  linkPagamento(pagamentos: any, reservaId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/contrato/linkparapagamento/${reservaId}`, pagamentos);
  }

  RelatorioContratos(DataInicial: any, DataFinal: any, DataAssinaturaInicial: any, DataAssinaturaFinal: any, Status: any): Observable<any> {
      return this.http.post<any>(`${API_RELATORIOS}/api/contrato/relatorio/`, { DataInicial: DataInicial, DataFinal: DataFinal, DataAssinaturaInicial: DataAssinaturaInicial, DataAssinaturaFinal: DataAssinaturaFinal, Status: Status }, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  GetDadosContrato(chave : string){
    return this.http.get<any>( `${GERENCIAMENTO}/api/contrato?Chave=${chave}`);
  }
   AtualizarAssinatura(assinatura : any, geolocalizacao:any){
    var headers = new HttpHeaders().append('noloader', 'noloader');
    if (geolocalizacao) {
      headers = headers.append('latitude', geolocalizacao.latitude);
      headers = headers.append('longitude', geolocalizacao.longitude);
    }
     return this.http.put<any>(`${GERENCIAMENTO}/api/Contrato`, assinatura, { headers: headers });
  }

  uploadDocumentoFrente(arquivo: any, id: number): Observable<any> {
    const url = `${GERENCIAMENTO}/api/Contrato/documentofrente/${id}/`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);

    return this.http.post(url, formData, { headers: headers });
  }
  uploadDocumentoVerso(arquivo: any, id: number): Observable<any> {
    const url = `${GERENCIAMENTO}/api/Contrato/documentoverso/${id}/`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);

    return this.http.post(url, formData, { headers: headers });
  }
   uploadAssinatura(assinatura: any, selfie: any, id: number): Observable<any> {

        const url = `${GERENCIAMENTO}/api/Contrato/assinatura/${id}/`;

        const headers = new HttpHeaders().append('noloader', 'noloader');
        const formData = new FormData();
        formData.append('Assinatura', assinatura);
        formData.append('Selfie', selfie);

        return this.http.post(url, formData, { headers: headers });

    }

  GerarPdfContrato(assinaturaId: any): Observable<any> {
    const headers = new HttpHeaders().append('noloader', 'noloader');
    return this.http.get(`${GERENCIAMENTO}/api/contrato/gerarpdf/${assinaturaId}`, { responseType: 'blob' });
  }

    GetPermissaoGeo(): Promise<{ permitido: boolean, coords?: any }> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        resolve({ permitido: false });  // Geolocalização não suportada
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve({ permitido: true, coords: position.coords }),  // Permissão concedida com coordenadas
          (error) => resolve({ permitido: false })     // Permissão negada ou outro erro
        );
      }
    });
  }
   requestCameraPermission(): Promise<{ permitido: boolean, stream?: MediaStream }> {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user'
        }
      }).then(stream => {
        resolve({ permitido: true, stream });
      }).catch(error => {
        console.error('Erro para acessar a câmera:', error);
        resolve({ permitido: false });
      });
    });
  }
}
