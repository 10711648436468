import { Component, OnInit, Input, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { NgModel, FormControlName  } from '@angular/forms';

@Component({
  selector: 'imagem-contrato',
  templateUrl: './imagem-contrato.component.html',
  styleUrls: ['./imagem-contrato.component.scss']
})
export class ImagemContratoComponent implements OnInit, AfterContentInit {
  @Input() width; //px
  @Input() height; //px
  @Input() maxwidth; //px
  @Input() maxheight; //px
  @Input() src: any = '';
  @Input() label: string;
  @Input() errorMessage: string;

  @Input() alterou = (x: boolean, url: string) => {
    if (x){
      this.url = null;
      this.selectedFile = null;
      this.src = url;
    }
  }

  @Output() submit = new EventEmitter<any>();


  url = null;
  selectedFile = null;

  constructor() { }

  ngAfterContentInit(){

  }

  ngOnInit() {
  }

  onUpload(){

    this.submit.emit(this.selectedFile);

    this.url = null;
    this.selectedFile = null;
  }

  onCancelled() {
    if (this.url !== null) {
      this.src = this.url;
    } else {
      this.src = '';  // ou defina para null, dependendo da lógica desejada
    }
    this.url = null;
    this.selectedFile = null;
  }

  onFileSelected(event){
    if (this.url != null){
      this.url = this.src;
    }
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.errorMessage = 'Somente imagens são suportadas.';
      return;
    }else{
      this.errorMessage = null;
    }

    this.selectedFile = event.target.files[0];

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.src = reader.result;
    };

  }

}
