import { Cidade } from '../../model';

export class Aeroporto{
  Id: number;
  Descricao: string;
  Iata: string;
  Nacional: boolean;
  CidadeId: number;
  Cidade ?: any = {Nome : ''};
  Vinculado?: any = {Descricao : ''};
  VinculadoId?: any;
  Vinculados?: any;

  TimeZone: any;
  Latitude: any;
  Longitude: any;
  display_field: string;

}
