import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { Evento, EventoInscricao } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class EventoHotelService {

  constructor(private http: HttpClient) { }


  salvar(eventoHotel: any): Observable<any> {
    if (eventoHotel.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/EventoHotel/editarEvento/${eventoHotel.Id}`, eventoHotel);
    }
    else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/EventoHotel/salvarEventoHotel`, eventoHotel);
    }
  }

  remover(id: Number): Observable<any>{
    return this.http.delete<any>( `${GERENCIAMENTO}/api/EventoHotel/remover/${id}`);
  }


  buscarPorParametro(param: string, pagina: Number): Observable<any>{
    const headers = new HttpHeaders().set('noloader', 't');

      if (param.length > 0)
      {
        return this.http.get<any>( `${GERENCIAMENTO}/api/Evento/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
      else
      {
        return this.http.get<any>( `${GERENCIAMENTO}/api/EventoHotel/buscarEventoHotel`, {headers: headers});
      }
  }

  // removerImagens(id: Number, index: number): Observable<any> {
  //   return this.http.delete<any>(`${GERENCIAMENTO}/api/evento/imagens/remover/${id}/${index}`);
  // }

  // buscarPorId(id: number): Observable<any>{
  //     return this.http.get<any>( `${GERENCIAMENTO}/api/Evento/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  // }

  // remover(id: Number): Observable<any>{
  //   return this.http.delete<any>( `${GERENCIAMENTO}/api/Evento/${id}`);
  // }

  // salvar(evento: Evento): Observable<any>{
  //   if (evento.Id > 0){
  //     return this.http.put<any>( `${GERENCIAMENTO}/api/Evento/${evento.Id}`, evento);
  //   }
  //   else
  //   {
  //     return this.http.post<any>( `${GERENCIAMENTO}/api/Evento/`, evento);
  //   }

  // }

  // //upload na imagem principal
  // uploadImagem(arquivo: any, id: number): Observable<any>{

  //   const url = `${GERENCIAMENTO}/api/evento/imagem/${id}/`;

  //   const headers = new HttpHeaders().append('noloader', 'noloader');
  //   const formData = new FormData();
  //   formData.append('Arquivo', arquivo);

  //   return this.http.post(url, formData, {headers : headers});

  // }

  // //para dar upload na lista de imagens
  // uploadImagens(arquivo: any,id: number, index: number): Observable<any> {

  //   const url = `${GERENCIAMENTO}/api/evento/imagens/${id}`;

  //   const headers = new HttpHeaders().append('noloader', 'noloader');
  //   const formData = new FormData();
  //   formData.append('Arquivo', arquivo);

  //   return this.http.post(url, formData, { headers: headers });

  // }


  // salvarInscricao(eventoInscricao: EventoInscricao): Observable<any>{
  //   if (eventoInscricao.Id > 0){
  //     return this.http.put<any>( `${GERENCIAMENTO}/api/Evento/Inscricao/${eventoInscricao.Id}`, eventoInscricao);
  //   }
  //   else
  //   {
  //     return this.http.post<any>( `${GERENCIAMENTO}/api/Evento/Inscricao/`, eventoInscricao);
  //   }

  // }

  // buscarInscricaoPorId(id: number): Observable<any>{
  //     return this.http.get<any>( `${GERENCIAMENTO}/api/Evento/Inscricao/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  // }

  // removerInscricaoRespostas(id: Number): Observable<any>{
  //   return this.http.delete<any>( `${GERENCIAMENTO}/api/Evento/removerInscricaoRepostas/${id}`);
  // }
}
