import { Agencia } from '../cadastro/agencia.model';
import { MarkupOfflineServico } from '../gerenciamento/servicooffline.model';

export class Markup {
  public  Id: number;
  public WebService?: any = {Descricao : ''};
  public WebServiceId: number;
  public Descricao: string;

  public MarkupDireto: any;
  public MarkupInternacional: any;


}


export class PerfilMarkup{
  public Id: number;
  public Descricao: string;
  public Markdowns: Markdown[];
  public Markups: Markup[];
  public MarkupsServicos : PerfilMarkupMarkupOfflineServico[];
  public Agencias: Agencia[];
  public Alteracoes: any;
  public Ativo: any;
}


export class Markdown {
  public Id: number;
  public PerfilMarkup?: any;
  public PerfilMarkupId: number;
  public Taxa: number;
  public Tipo: number;
  public DataInicial: any;
  public DataFinal: any;
}

export class PerfilMarkup_Markup {
    Markup?: any = {Descricao : ''};
}

export class PerfilMarkup_MarkupOfflineServico {
    MarkupOfflineServico?: any = {Descricao : ''};
}

export class PerfilMarkupMarkupOfflineServico{
  public Id: number;
  public MarkupOfflineServicoId: number;
  public MarkupOfflineServico: any = {Descricao : ''};
  public De : any;
  public Ate : any;
  public Ativo: boolean;
  public PerfilMarkup: any;
  public PerfilMarkupId: number;
}
