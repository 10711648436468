import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { CategoriaOfflineApartamento } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class CategoriaOfflineApartamentoService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{

      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlinecategoriaapartamento/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlinecategoriaapartamento?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlinecategoriaapartamento/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlinecategoriaapartamento/${id}`);
    }

    salvar(categoriaOfflineApartamento: CategoriaOfflineApartamento): Observable<any>{
      if (categoriaOfflineApartamento.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/offlinecategoriaapartamento/${categoriaOfflineApartamento.Id}`, categoriaOfflineApartamento);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/offlinecategoriaapartamento/`, categoriaOfflineApartamento);
      }

    }

    uploadImagem(arquivo: any, id: number): Observable<any>{

      const url = `${GERENCIAMENTO}/api/offlinecategoriaapartamento/imagem/${id}/`;

      const headers = new HttpHeaders().append('noloader', 'noloader');
      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers : headers});

    }

    removerImagem(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlinecategoriaapartamento/imagem/remover/${id}`);
    }

  }
