import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';


import { TURISMO , ITENSPORPAGINA} from '../../app.config';

@Injectable()
export class VoucherService{
  constructor(private http: HttpClient){}

  //     exibirVoucher(VoucherKey):Observable<any>{
  // //      const headers = new HttpHeaders().set('noloader', 't');
  //     const headers = new HttpHeaders();
  //       return this.http.get<any>(`${API}/api/voucher/${VoucherKey}`, {headers: headers});
  //     }

  exibirVoucher(VoucherKey):Observable<any> {
    return this.http.get<any>(`${TURISMO}/api/voucher/${VoucherKey}`, { responseType: 'blob' as 'json' })
      .map(res => {
      return new Blob([res], { type: 'application/pdf', });
    });
  }

  exibirVoucherUrl(VoucherKey): string {
    return `${TURISMO}/api/voucher/${VoucherKey}`;
  }

  gerarVoucher(tipo, item, endereco ):Observable<any>{

    return this.http.post<any>(`${TURISMO}/api/reservas/voucher/${tipo}`, {Id: item.Id, EnderecoBase: endereco});
  }

  enviarVoucher(VoucherKey, email, Tipo) : Observable<any> {
    return this.http.patch<any>(`${TURISMO}/api/VoucherEnvio/${VoucherKey}/${Tipo}`, { Email: email });
  }

  exibirVoucherHtml(VoucherKey):Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.patch<any>(`${TURISMO}/api/VoucherHtml/${VoucherKey}?html=true`, { headers, responseType: 'text' as 'json'});
  }


  }
