import { Reserva } from '../turismo/reserva.model';

export class TeleVendasPagamento {
  public Id: number = 0;

  public Nome: string;
  public CPF : string;
  public Referencia : string;
  public PagamentoCartao: any;

  public Valor : number;
  public Parcelas : number;
  public TemOrcamento : any;
  public Reserva?: Reserva;
  public ReservaId?: number;

  public Status: number;
  public StatusDesc: string;

  public CodigoPedido?: string;
  public CodigoTransacao?: string;

  public EmailDestinatarioCobranca: string;

  public VisualizouEmail?: boolean;
  public LinkTelevendas?: string;

  public DataPagamentoTelevendas?: Date;

  public Localizacao?: string;

  public Telefone?: string;

  public constructor(){
    this.PagamentoCartao = [
      {
        WebService: null,
        WebServiceId: '',
        Nome: '',
        Numero: '',
        DataValidade: '',
        DigitoVerificador: '',
        Bandeira: '',
      }
    ]
  }



}
