import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmacaoComponent } from './confirmacao.component';
import { TranslateService } from '@ngx-translate/core';
import { Util } from '../../services/utils.service';

@Injectable()
export class ConfirmacaoService {

  util: any;

  constructor(
    private modalService: NgbModal,
    private translate?: TranslateService,
  ) {
    this.util = new Util(translate);
  }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    typeButton: string = 'danger',
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmacaoComponent, { centered: true });
    modalRef.componentInstance.title = this.util.fazerTraducao(title);
    modalRef.componentInstance.message = this.util.fazerTraducao(message);
    modalRef.componentInstance.btnOkText = this.util.fazerTraducao(btnOkText);
    modalRef.componentInstance.btnCancelText = this.util.fazerTraducao(btnCancelText);
    modalRef.componentInstance.typeButton = typeButton;

    return modalRef.result;
  }

}
