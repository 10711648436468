import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { ServicoOffline } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA,API_RELATORIOS } from '../../app.config';

@Injectable()
export class ServicoOfflineService {
    De: string;
    Ate: string;

    constructor(private http: HttpClient) { }


    buscarPorParametro(param: string, pagina: Number, fornecedorId = 0, tipoServico = 0): Observable<any> {

        const headers = new HttpHeaders().set('noloader', 't');

        if (param && param.length > 0) {

            //pra quando a pesquisa tiver um "+ ou /", pois o IIS bloqueia o request com esses carácteres
            // param = param.replace("+", "_").replace("/", "$").replace("*", "¬");

            const formData = new FormData();
            formData.append('Valor', param);

            return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservico/list?FornecedorId=${fornecedorId}&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, formData, { headers: headers });
        }
        else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/offlineservico?FornecedorId=${fornecedorId}&categoriaservico=${tipoServico}&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }

    buscarPorFornecedorETipoId(Pesquisa: string, FornecedorId: number, TipoId: number) {
        const headers = new HttpHeaders().set('noloader', 't');
        return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservicos/listaportipo`, { Pesquisa: Pesquisa, FornecedorId: FornecedorId, TipoId: TipoId }, { headers: headers });
    }



    buscarPorParametroErp(param: string, pagina: Number, fornecedorId = 0, enumCategoria: any, ativo: any): Observable<any> {

        const headers = new HttpHeaders().set('noloader', 't');

        if (param.length > 0 || enumCategoria >= -1) {

            //pra quando a pesquisa tiver um "+ ou /", pois o IIS bloqueia o request com esses carácteres
            // param = param.replace("+", "_").replace("/", "$").replace("*", "¬");

            const formData = new FormData();
            formData.append('Valor', param);

            return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservico/list?FornecedorId=${fornecedorId}&CategoriaServico=${enumCategoria}&Status=${ativo}&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, formData, { headers: headers });
        }
        else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/offlineservico?FornecedorId=${fornecedorId}&CategoriaServico=${enumCategoria}&Status=${ativo}&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }

    buscarPorId(id: number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        return this.http.get<any>(`${GERENCIAMENTO}/api/offlineservico/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/offlineservico/${id}`);
    }

    salvar(servicooffline: ServicoOffline): Observable<any> {
        if (servicooffline.Id > 0) {
            return this.http.put<any>(`${GERENCIAMENTO}/api/offlineservico/${servicooffline.Id}`, servicooffline);
        } else {
            return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservico/`, servicooffline);
        }

    }

    uploadImagem(arquivo: any, id: number): Observable<any> {

        const url = `${GERENCIAMENTO}/api/offlineservico/imagem/${id}/`;

        const headers = new HttpHeaders().append('noloader', 'noloader');
        const formData = new FormData();
        formData.append('Arquivo', arquivo);

        return this.http.post(url, formData, { headers: headers });

    }

    removerImagem(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/offlineservico/imagem/remover/${id}`);
    }

    vinculaCidade(id: number, cidadeId: number): Observable<any> {
        return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservico-cidade/vincular/${id}/${cidadeId}`, {});
    }

    desvincularCidade(id: number, cidadeId: number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/offlineservico-cidade/desvincular/${id}/${cidadeId}`, {});
    }

    vincularAgenciaExclusiva(id: number, agenciaId: number): Observable<any> {
        return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservico-agencia/vincular/${id}/${agenciaId}`, {});
    }

    desvincularAgenciaExclusiva(id: number, agenciaId: number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/offlineservico-agencia/desvincular/${id}/${agenciaId}`, {});
    }

    duplicarTarifa(tarifaId: number): Observable<any> {
        return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservico/duplicar/tarifa/${tarifaId}`, {});
    }

    duplicarServico(id: number): Observable<any> {
        return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservico/duplicar/${id}`, {});
    }

    buscarOfflineServicoTipo(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        if (param.length > 0) {
            return this.http.get<any>(`${GERENCIAMENTO}/api/offlineservicotipo/list/${param.trim()}`);
        } else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/offlineservicotipo/list/?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
        }
    }

    getFornecedores(): Observable<any> {
        const headers = new HttpHeaders().append('noloader', 'noloader');

        //const formData = new FormData();

        return this.http.get<any>(
            `${GERENCIAMENTO}/api/servico/getFornecedores`
        );
    }

    getServicos(): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');

        return this.http.get<any>(`${GERENCIAMENTO}/api/servico/getServicos/`, { headers: headers });
    }

    getCidades(): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');

        return this.http.get<any>(`${GERENCIAMENTO}/api/servico/getCidades/`, { headers: headers });
    }

    tarifarioServicoPorAgenciaValidade(fornecedorId: string, checkInInicial: string, checkInFinal: string, agenciaId: string, fornecedor: string): Observable<any> {
        const headers = new HttpHeaders().append('noloader', 'noloader');
        const formData = new FormData();

        formData.append('FornecedorId', fornecedorId);
        formData.append('De', checkInInicial);
        formData.append('Ate', checkInFinal);
        formData.append('AgenciaId', agenciaId);
        formData.append('Fornecedor', fornecedor);


        return this.http.post<any>(`${API_RELATORIOS}/api/servico/TarifarioServicoPorAgenciaValidade`, formData);
    }

    vincularWebService(id: number, WebserviceId: number): Observable<any> {
        return this.http.post<any>(`${GERENCIAMENTO}/api/offlineservico-webservice/vincular/${id}/${WebserviceId}`, {});
    }

    desvincularWebService(id: number, WebserviceId: number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/offlineservico-webservice/desvincular/${id}/${WebserviceId}`, {});
    }

}
