import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import {Cidade} from '../../model';
import { EMPRESAS, ITENSPORPAGINA } from '../../app.config';
@Injectable()
export class TBOService {

    constructor(private http: HttpClient) { }

    buscarPaises(): Observable<any> {
        return this.http.get<any>(`${EMPRESAS}/api/TBO/paises`);
    }

    buscarCidades(paisws: string, cidadews: string): Observable<any> {
        if (cidadews != "") {
            return this.http.get<any>(`${EMPRESAS}/api/TBO/cidades/${paisws}/${cidadews}`);
        } else {
            return this.http.get<any>(`${EMPRESAS}/api/TBO/cidades/${paisws}`);
        }
    }

    vincularCidades(cidadesws: any, cidade: Cidade): Observable<any> {
        return this.http.post<any>(`${EMPRESAS}/api/TBO/vincular-cidades/${cidade.Id}`, cidadesws);
    }

    vincularHoteis(cidadesws: any, cidade: Cidade): Observable<any> {
        return this.http.post<any>(`${EMPRESAS}/api/TBO/vincular-hoteis/${cidade.Id}`, cidadesws);
    }
}
