import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { CacheHotel } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class CacheHotelService {

    constructor(private http: HttpClient) { }


    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        if (param.length > 0) {
            return this.http.get<any>(`${GERENCIAMENTO}/api/CacheHotel/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        } else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/CacheHotel?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}/api/CacheHotel/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/CacheHotel/${id}`);
    }

    salvar(cacheHotel: CacheHotel): Observable<any> {
        if (cacheHotel.Id > 0) {
            return this.http.put<any>(`${GERENCIAMENTO}/api/CacheHotel/${cacheHotel.Id}`, cacheHotel);
        } else {
            return this.http.post<any>(`${GERENCIAMENTO}/api/CacheHotel/`, cacheHotel);
        }

    }
    
    vincularCidadeHotel(CodigoCidadeFornecedor: string, CidadeId: number, WebserviceId: number): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}/api/CacheHotel/Vincular/${CodigoCidadeFornecedor}/${CidadeId}/${WebserviceId}`);
    }

    enderecoExemplo(CodigoCidadeFornecedor: string): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}/api/CacheHotel/EnderecoExemplo/${CodigoCidadeFornecedor}`);
    }
}
