import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class PacoteBloqueioAereoService{

  constructor(private http: HttpClient){}

    vincularBloqueio (id: number, bloqueioId: number): Observable<any> {
      return this.http.post<any>( `${GERENCIAMENTO}/api/pacotebloqueioaereo/vincular/${id}/${bloqueioId}`, {});
    }

    desvincularBloqueio (id: number, bloqueioId: number): Observable<any> {
      return this.http.delete<any>( `${GERENCIAMENTO}/api/pacotebloqueioaereo/desvincular/${id}/${bloqueioId}`, {});
    }

}
