import { Cidade } from '../endereco.model';

export class Empresa{
  public Id: number;
  public Cidade: Cidade = new Cidade();

  public RazaoSocial: String;
  public NomeFantasia: String;
  public CNPJ: String;
  public InscricaoEstadual: String;
  public InscricaoMunicipal: String;
  public WebSite: String;
  public EmailPadrao: String;
  public EmailFinanceiro: String;
  public Telefone: String;
  public DescricaoEmergencia: String;

  public MoedaPagamentoId : number;

  public LogoInternet: string = '';
  public LogoIntranet: string = '';
  public LogoInternetApresentacao: any;
  public LogoIntranetApresentacao: any;
  public Internacional: any;
  public Cep: any;
  public Logradouro: any;
  public Numero: any;
  public Bairro: any;
  public Complemento: any;
  public Ativo: any;
  public Alteracoes: any;
  public ConfiguracoesAdicionais: any;
  public existe: boolean = false;

}
