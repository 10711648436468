<div *ngIf="verLogin && !Arkbeds" class="row p-3 m-md-0 m-2 tamanho-login" [class.black-frt]="BlackFRT">

  <div *ngIf="carregando" class="overlay">
    <img src="assets/img/loading3.gif" class="imagem">
  </div>


  <span *ngIf="!Apenas_FRT" class="col-md-12">
    <div class="col-md-12 text-center">
      <h1 id="title" class="title" style="font-family: var(--TextoTitulo) !important">{{FraseHome | translate}}</h1>
    </div>

    <form #form="ngForm" aria-labelledby="title" role="form" novalidate class="col-md-12 m-0 pr-0">

      <div *ngIf="errors?.length > 0" class="alert alert-danger col-md-12">
        <p *ngFor="let erro of errors">{{erro | translate}}</p>
      </div>

      <app-input [errorMessage]="'Email obrigatório' | translate" showTip="false">
        <label>{{'Email' | translate }}:</label>
        <input nbInput status="{{(email.invalid && (email.dirty || email.touched)) ? 'danger' :''}}" required="required"
          class="form-control" [(ngModel)]="user.email" #email="ngModel" name="email" placeholder="{{Email}}"
          autofocus />
      </app-input>
      <app-input [errorMessage]="'Senha Obrigatória' | translate" showTip="false">
        <label>{{'Senha' | translate}}:</label>
        <input nbInput status="{{(senha.invalid && (senha.dirty || senha.touched)) ? 'danger' :''}}" required="required"
          class="form-control" [(ngModel)]="user.password" #senha="ngModel" name="password" type="password"
          placeholder="{{Senha}}" />
      </app-input>

      <div class="form-control-group accept-group col-md-12 text-center p-2 d-flex justify-content-around">
        <div class="col-md" *ngIf="urlSite.length > 0"> <a *ngIf="urlSite.length > 0" class="forgot-password"
            href="{{urlSite}}">{{'Registre-se' | translate}}</a>
        </div>
        <div class="col-md"> <a target="_blank"
            *ngIf="enderecoapi.length > 0 && enderecoapi == 'https://api.comprefacil.tur.br'" class="forgot-password"
            href="https://api.comprefacil.tur.br/uploads/Termos_de_Uso_e_Política_de_Privacidade_FRT.pdf">{{'Política de privacidade' | translate}}</a>
        </div>
        <div class="col-md"><a class="forgot-password" href=""
            (click)="toggleRecuperarSenha($event)">{{'Recuperar senha' | translate}}</a></div>
      </div>

      <button (click)="login()" [disabled]="form.invalid"
        class="btn btn-success btn-block">{{'Login' | translate}}</button>
    </form>
  </span>

  <span *ngIf="Apenas_FRT" class="col-md-12">
    <div class="row">
      <form #form="ngForm" aria-labelledby="title" role="form" novalidate
        class="login-frt h-100 col-md-12 d-flex align-items-center justify-content-center flex-column">

        <div class="col-md-12 logo-frt">
          <img src="../../../assets/img/FdaFRT.png" alt="FRT_Logo" class="">
        </div>

        <span style="background-color: transparent;" *ngIf="errors?.length > 0"
          class="alert alert-danger col-md-12 p-0 m-0 text-center">
          <p class="p-0 m-0" *ngFor="let erro of errors">{{erro | translate}}</p>
        </span>

        <h4 class="titulo">{{FraseHome}}</h4>

        <app-input [errorMessage]="'Email obrigatório' | translate" showTip="false" class="inputForm col-md-12">
          <input nbInput status="{{(email.invalid && (email.dirty || email.touched)) ? 'danger' :''}}"
            required="required" class="form-control input-form" [(ngModel)]="user.email" #email="ngModel" name="email"
            placeholder="{{Email}}" autofocus />
        </app-input>

        <app-input [errorMessage]="'Senha Obrigatória' | translate" showTip="false" class="inputForm col-md-12">
          <input nbInput status="{{(senha.invalid && (senha.dirty || senha.touched)) ? 'danger' :''}}"
            required="required" class="form-control input-form" [(ngModel)]="user.password" #senha="ngModel"
            name="password" type="password" placeholder="{{Senha}}" />
        </app-input>

        <button (click)="login()" [disabled]="form.invalid"
          class="btn btn-success btn-block col-12">{{'Login' | translate}}</button>

        <div class="politica-e-senha col-12 mt-lg-3 mt-1 mb-lg-3 mb-2">
          <div class="col-12 mt-1 mt-lg-0"><span class="forgot-password">{{"Desejo" | translate}} </span><a
              class="forgot-password" style="margin-left: 0 !important" href=""
              (click)="toggleRecuperarSenha($event)">{{"recuperar senha" | translate}}</a></div>
          <div class="col-12 mt-2 mb-4 d-inline-flex justify-content-center text-nowrap"><span
              class="forgot-password">{{'Política de privacidade' | translate}}</span>
            <a target="_blank" class="forgot-password d-inline-flex"
              href="https://api.comprefacil.tur.br/uploads/Termos_de_Uso_e_Política_de_Privacidade_FRT.pdf">
              Download
            </a>
          </div>
        </div>

      </form>
    </div>
  </span>
</div>

<div *ngIf="verLogin && Arkbeds" class="d-flex justify-content-center align-items-center h-md-100">
  <div *ngIf="carregando" class="overlay">
    <img src="assets/img/loading3.gif" class="imagem">
  </div>
  <div class="row container-fluid login-page mr-3 ml-3">
    <div class="col-md-4 col-8" [class.arkbeds]="Arkbeds">
      <div class="row d-flex justify-content-center">
        <form #form="ngForm" aria-labelledby="title" role="form" novalidate class="login-ark">

          <div class="col-12 d-flex justify-content-center logo-ark">
            <img class="img-fluid" src="../../../assets/img/logo_arkbeds.png" alt="logo-ark">
          </div>
          <div class="col-12 d-flex justify-content-center text-description">
            <p>
              {{ 'Faça seu login para ter acesso ao nosso portal e aproveite as melhores negociações em produtos no mundo inteiro' | translate }}
            </p>
          </div>

          <span style="background-color: transparent;" *ngIf="errors?.length > 0"
            class="alert alert-danger col-md-12 p-0 m-0 text-center">
            <p class="p-0 m-0" *ngFor="let erro of errors">{{erro | translate}}</p>
          </span>

          <app-input [errorMessage]="'Email Obrigatório' | translate" showTip="false" class="inputForm col-md-12">
            <label style="font-weight: bold;">{{'Email' | translate }}</label>
            <input nbInput status="{{(email.invalid && (email.dirty || email.touched)) ? 'danger' :''}}"
              required="required" class="form-control" [(ngModel)]="user.email" #email="ngModel" name="email"
              autofocus />
          </app-input>

          <app-input [errorMessage]="'Senha Obrigatória' | translate" showTip="false" class="inputForm col-md-12">
            <label style="font-weight: bold;">{{'Senha' | translate }}</label>
            <input nbInput status="{{(senha.invalid && (senha.dirty || senha.touched)) ? 'danger' :''}}"
              required="required" class="form-control input-form" [(ngModel)]="user.password" #senha="ngModel"
              name="password" type="password" />
          </app-input>

          <div class="row forget-password d-md-flex align-items-md-center">
            <div class="col-md-5 col-12 d-flex justify-content-center align-items-center">
              <a href="" (click)="toggleRecuperarSenha($event)">{{'Esqueci minha senha' | translate }}</a>
            </div>
            <div class="col-md-7 col-12">
              <button (click)="login()" [disabled]="form.invalid"
                class="btn btn-success btn-block">{{'Login' | translate}}</button>
            </div>
          </div>

          <div class="row create-acount d-flex align-items-center justify-content-center">
            <div class="col-md-6 d-md-flex justify-content-md-end pr-md-0 col-6 d-flex justify-content-end pr-1">
              <a href="{{urlSite}}">{{'Não possui conta?' | translate }}</a>
            </div>
            <div
              class="col-md-6 d-md-flex justify-content-md-start align-items-md-center pl-md-1 col-5 d-flex justify-content-start align-items-center pl-0">
              <a href="{{urlSite}}" style="font-weight: 700;">{{'Cadastre-se' | translate }}</a>
            </div>
          </div>

        </form>
      </div>
    </div>

    <div class="col-md-6 col-12 welcome">
      <div class="row">
        <div class="title col-12">
          <h1>{{ 'Bem-Vindo' | translate}}</h1>
        </div>
        <div class="submit col-12 mb-5">
          <p>{{ 'Welcome - Bienvenido' | translate}}</p>
        </div>
      </div>
    </div>

  </div>
</div>




<div *ngIf="verRecuperarSenha && !Arkbeds" class="row m-md-0 m-2"
  style="min-width: 43vh; min-height: 40vh; height: auto;" [class.black-frt]="BlackFRT == true">
  <span *ngIf="!Apenas_FRT" class="col-md-12">
    <div class="col-md-12 text-center">
      <h2 id="title" class="title" style="font-family: var(--TextoTitulo) !important">
        {{'Recuperação de Senha' | translate}}</h2>
    </div>

    <form class="col-md-12 m-0 pr-0" #formRecuperar="ngForm" aria-labelledby="title" role="form" novalidate
      (submit)="recuperarSenha($event)">

      <div *ngIf="errorSenha" class="alert alert-danger text-center">
        <p *ngFor="let erro of errorSenha">{{erro | translate}}</p>
      </div>
      <div *ngIf="successSenha" class="alert alert-success text-center">
        <p>{{successSenha | translate}}</p>
      </div>

      <app-input errorMessage="Email obrigatório" showTip="false">
        <label>Email:</label>
        <input nbInput required="required" class="form-control" [(ngModel)]="emailSenha" name="emailSenha"
          placeholder="Email" autofocus />
      </app-input>

      <div class="form-control-group accept-group">
        <button type="button" (click)="recuperarSenha()" [disabled]="formRecuperar.invalid"
          class="btn btn-success">{{'Recuperar senha' | translate}}</button>
        <button type="button" *ngIf="Cancelar" (click)="toggleRecuperarSenha($event)" class="btn btn-danger">
          {{'Cancelar' | translate}}</button>
        <button type="button" *ngIf="Sair" (click)="toggleRecuperarSenha($event)"
          class="btn btn-primary">{{'Sair' | translate}}</button>
      </div>

    </form>
  </span>

  <span *ngIf="Apenas_FRT" class="col-md-12">
    <div class="row recuperarSenha">
      <form #form="ngForm" aria-labelledby="title" role="form" novalidate
        class="login-frt h-100 col-md-12 d-flex align-items-center justify-content-center flex-column">

        <div class="col-md-12 logo-frt">
          <img src="../../../assets/img/FdaFRT.png" alt="F da FRT">
        </div>

        <span style="background-color: transparent;" *ngIf="errors?.length > 0"
          class="alert alert-danger text-center col-md-12 p-0 m-0">
          <p class="p-0 m-0" *ngFor="let erro of errors">{{erro | translate}}</p>
        </span>

        <h4 class="titulo recuperar" style="color: gray;">{{RecuperarSenha | translate}}</h4>

        <app-input errorMessage="Email obrigatório" showTip="false" class="inputForm col-md-12">
          <input nbInput status="{{(email.invalid && (email.dirty || email.touched)) ? 'danger' :''}}"
            required="required" class="form-control input-form" [(ngModel)]="user.email" #email="ngModel" name="email"
            placeholder="{{Email}}" autofocus />
        </app-input>

        <button (click)="login()" [disabled]="form.invalid"
          class="btn btn-success btn-block col-12 ">{{'Recuperar Senha' | translate}}</button>
        <button type="button" *ngIf="Cancelar" (click)="toggleRecuperarSenha($event)"
          class="btn btn-danger btn-block col-12 mb-4">
          {{'Cancelar' | translate}}
        </button>

        <div class="politica-e-senha col-12 mt-lg-3 mt-2 mb-lg-3 mb-2" *ngIf="clicou">
          <div class="col-12"><span class="forgot-password">{{"Por gentileza, verifique seu e-mail" | translate}}</span>
          </div>
        </div>

      </form>
    </div>
  </span>
</div>

<div *ngIf="verRecuperarSenha && Arkbeds" class="d-flex justify-content-center align-items-center h-md-100">
  <div class="row container-fluid login-page">
    <div class="col-md-4 col-12" [class.arkbeds]="Arkbeds">
      <div class="row d-flex justify-content-center">
        <form #form="ngForm" aria-labelledby="title" role="form" novalidate class="login-ark" style="width: 80%;">

          <div class="col-12 d-flex justify-content-center logo-ark">
            <img class="img-fluid" src="../../../assets/img/logo_arkbeds.png" alt="logo-ark">
          </div>
          <div class="col-12 d-flex justify-content-center text-description">
            <p>{{'Utilize seu email para redefinir sua senha.' | translate}}</p>
          </div>

          <span style="background-color: transparent;" *ngIf="errors?.length > 0"
            class="alert alert-danger col-md-12 p-0 m-0 text-center">
            <p class="p-0 m-0" *ngFor="let erro of errors">{{erro | translate}}</p>
          </span>
          <span style="background-color: transparent;" *ngIf="errorSenha"
            class="alert alert-danger col-md-12 p-0 m-0 text-center">
            <p class="p-0 m-0" *ngFor="let erro of errorSenha">{{erro | translate}}</p>
          </span>

          <app-input [errorMessage]="'Email Obrigatório' | translate" showTip="false" class="inputForm col-md-12">
            <label>{{'Email' | translate }}</label>
            <input nbInput required="required" class="form-control" [(ngModel)]="emailSenha" name="emailSenha"
              autofocus />
          </app-input>

          <div class="row recover-password d-flex justify-content-around m-0">
            <button type="button" (click)="recuperarSenha()" [disabled]="form.invalid"
              class="recover-button col-12 mt-3">{{'Recuperar Senha' | translate}}</button>
            <button type="button" *ngIf="Cancelar" (click)="toggleRecuperarSenha($event)"
              class="cancel-button col-12 mt-3">
              {{'Cancelar' | translate}}
            </button>
          </div>

          <div *ngIf="successSenha" class="alert alert-success text-center" style="background-color: transparent !important;">
            <p>{{successSenha | translate}}</p>
          </div>

          <div class="row create-acount d-flex align-items-center justify-content-center">
            <div class="col-md-6 d-md-flex justify-content-md-end pr-md-0 col-6 d-flex justify-content-end pr-1">
              <a href="{{urlSite}}">{{'Não possui conta?' | translate }}</a>
            </div>
            <div
              class="col-md-6 d-md-flex justify-content-md-start align-items-md-center pl-md-1 col-5 d-flex justify-content-start align-items-center pl-0">
              <a href="{{urlSite}}" style="font-weight: 700;">{{'Cadastre-se' | translate }}</a>
            </div>
          </div>

        </form>
      </div>
    </div>

    <div class="col-md-6 col-12 welcome">
      <div class="row">
        <div class="title-reset col-12">
          <h1>Reset Password</h1>
        </div>
        <div class="submit-reset col-12">
          <p>Redefinir senha - Nueva contraseña</p>
        </div>
      </div>
    </div>
  </div>
</div>
