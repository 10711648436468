import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';




import { TURISMO, ITENSPORPAGINA} from '../../app.config';

@Injectable()
export class FaturaService{
  constructor(private http: HttpClient){}


    buscarPorParametro(agenciaId: number,  pagina: number = 1): Observable<any>{
      // const headers = new HttpHeaders().set('noloader', 't');
        return this.http.get<any>( `${TURISMO}/api/Fatura/${agenciaId}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}` );
    }

    gerarBoleto(faturaId): Observable<any>{
        return this.http.get<any>( `${TURISMO}/api/Fatura/${faturaId}/gerar-boleto` );
    }

    detalheFatura(faturaId): Observable<any>{
        return this.http.get<any>( `${TURISMO}/api/Fatura/detalhefatura/${faturaId}` );
    }

}
