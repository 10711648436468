<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title"></h4>
</div>

<div class="modal-body">

  <div *ngIf="verLogin">
    <h1 id="title" class="title" style="font-family: var(--TextoTitulo) !important">{{'Insira suas credenciais novamente para continuar' | translate}}</h1>
  
    <form #form="ngForm" aria-labelledby="title" role="form" novalidate>
  
      <div *ngIf="errors?.length > 0" class="alert alert-danger">
        <p *ngFor="let erro of errors">{{erro}}</p>
      </div>
  
      <app-input errorMessage="Email obrigatório" showTip="false">
        <label>{{'Email' | translate }}:</label>
        <input nbInput status="{{(email.invalid && (email.dirty || email.touched)) ? 'danger' :''}}" required="required"
          class="form-control" [(ngModel)]="user.email" #email="ngModel" name="email" placeholder="Email" autofocus />
      </app-input>
      <app-input errorMessage="Senha Obrigatória" showTip="false">
        <label>{{'Senha' | translate}}:</label>
        <input nbInput status="{{(senha.invalid && (senha.dirty || senha.touched)) ? 'danger' :''}}" required="required"
          class="form-control" [(ngModel)]="user.password" #senha="ngModel" name="password" type="password"
          placeholder="Senha" />
      </app-input>
  
      <div class="form-control-group accept-group">
        <a *ngIf="urlSite.length > 0" class="forgot-password" href="{{urlSite}}">{{'Registre-se' | translate}}</a>
          <a target="_blank" *ngIf="enderecoapi.length > 0 && enderecoapi == 'https://api.comprefacil.tur.br'" class="forgot-password" href="https://api.comprefacil.tur.br/uploads/Termos_de_Uso_e_Política_de_Privacidade_FRT.pdf">{{'Política de privacidade' | translate}}</a>
        <a class="forgot-password" href="" (click)="toggleRecuperarSenha($event)">{{'Recuperar senha' | translate}}</a>
      </div>
  
      <button (click)="login()" [disabled]="form.invalid" class="btn btn-success btn-block mt-3">{{'Entrar' | translate}}</button>
    </form>
  </div>
  
  <div *ngIf="verRecuperarSenha">
    <h2 id="title" class="title" style="font-family: var(--TextoTitulo) !important">{{'Recuperação de Senha' | translate}}</h2>
  
    <form #formRecuperar="ngForm" aria-labelledby="title" role="form" novalidate (submit)="recuperarSenha($event)">
  
      <div *ngIf="errorSenha" class="alert alert-danger">
        <p *ngFor="let erro of errorSenha">{{erro}}</p>
      </div>
      <div *ngIf="successSenha" class="alert alert-success">
        <p>{{successSenha}}</p>
      </div>
  
      <app-input errorMessage="Email obrigatório" showTip="false">
        <label>Email:</label>
        <input nbInput required="required" class="form-control" [(ngModel)]="emailSenha" name="emailSenha"
          placeholder="Email" autofocus />
      </app-input>
  
      <div class="form-control-group accept-group">
      <button type="button" (click)="recuperarSenha()" [disabled]="formRecuperar.invalid"
        class="btn btn-success">{{'Recuperar senha' | translate}}</button>
        <button type="button" *ngIf="Cancelar" (click)="toggleRecuperarSenha($event)"class="btn btn-danger">{{ 'Cancelar' | translate}}</button>
        <button type="button" *ngIf="Sair" (click)="toggleRecuperarSenha($event)" class="btn btn-primary">{{'Sair' | translate}}</button>
      </div>
  
  
  
    </form>
  </div>

</div>

<div class="modal-footer" style="display:block">

</div>