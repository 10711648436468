import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { IntranetConteudo } from '../../model/intranet/conteudo.model';
import { API, EMPRESAS, ITENSPORPAGINA, GERENCIAMENTO } from '../../app.config';
import { LoginService } from '../login.service';
import { IntranetArquivo } from '../../model/intranet/arquivo.model';
import * as moment from 'moment';

@Injectable()

export class IntranetConteudoService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService
  ) { }

  buscarPorId(id: number): Observable<any> {

    return this.http.get<any>(`${API}/api/intranet/conteudo/${id}`);
  }

  buscarConteudoPorCategoriaId(id: number, dashboard = false): Observable<any> {
    if(dashboard){
    return this.http.get<any>(`${API}/api/intranet/conteudo/categoria/${id}/true`, { headers: new HttpHeaders().set('noloader', 'true') });
    } else {
      return this.http.get<any>(`${API}/api/intranet/conteudo/categoria/${id}/false`, { headers: new HttpHeaders().set('noloader', 'true') });
    }
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/intranet/conteudo/${id}`);
  }

  salvar(objetoClass: IntranetConteudo): Observable<any> {

    if (objetoClass.Id > 0) {
      return this.http.put<any>(`${API}/api/intranet/conteudo/${objetoClass.Id}`, objetoClass);
    } else {
      return this.http.post<any>(`${API}/api/intranet/conteudo`, objetoClass);
    }
  }

  buscarPorParametro(param: string, pagina: Number, trazTudo: boolean = false): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/intranet/conteudo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${trazTudo ? 50 : ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/intranet/conteudo/list?Pagina=${pagina}&ItensPorPagina=${12}`, { headers: headers });
    }
  }

  buscarPorParametroTodos(param: string, pagina: Number): Observable<any>{
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/intranet/conteudo/listCadastro/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/intranet/conteudo/listCadastro?Pagina=${pagina}&ItensPorPagina=${12}`, { headers: headers });
    }
  }

  buscarDashboardConteudos(pagina: Number){
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/intranet/conteudo/grupo/usuario?Pagina=${pagina}&ItensPorPagina=${12}`, { headers: headers });
  }

  DesvinculaGrupoConteudo(conteudoId: number, grupoId: number): Observable<any> {
    return this.http.get<any>(`${API}/api/intranet/conteudo/grupo/desvincula/${conteudoId}/${grupoId}`);
  }
  VinculaGrupoConteudo(conteudoId: number, grupoId: number): Observable<any> {
    return this.http.get<any>(`${API}/api/intranet/conteudo/grupo/vincula/${conteudoId}/${grupoId}`);
  }

}
