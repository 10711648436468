import { Cidade } from '../endereco.model';

export class Servico {
  public Id: number;
  public selecionado: boolean;
  public ExtraIntegracao2: ExtraIntegracao;
  public CodigoFornecedor: any;
  public Titulo: string;
  public CategoriaServico: string;
  public Fornecedor: string;
  public Descricao: string;
  public Imagens = [];
  public MoedaListagem: any;
  public ValorListagem;
}
class ExtraIntegracao {
  public NomeFornecedor: string;
  public EnderecoFornecedor: string;
  public TelefoneFornecedor: string;
  public CidadeFornecedor: string;
  public EstadoFornecedor: string;
  public PaisFornecedor: string;
  public Token: string;
  public CategoriaServico: string;
}

export enum TIPO {
  TODOS,
  ATIVIDADE,
  INGRESSO,
  TRANSFER,
}

export class BuscaServicoRQ {

  public De: any;
  public Ate: any;
  public Adt: number;
  public IdadesChd: number[] = [];
  public Cidade: Cidade = new Cidade();
  public Tipo: TIPO;
  public Guid: string;
  public AgenciaId: number;
  public ReservaId: number;
  public FiltroServico: FiltroServico = new FiltroServico();
  public PacoteId = 0;
  public ServicoExclusivo: boolean = false;
}

export class FiltroServico {

  public Ativo: boolean = null;
  public ValorMinimo: number;
  public ValorMaximo: number;
  public Titulo: string;
  public Categoria: number = -1;
  public Tipo: string = "";
}

export class ExtraIntegracaoValoresBusca {
  public Data: string;
  public Ingresso: Ingresso;
  public Horario: string;
  public CarrinhoId: string;
}

class Ingresso {
  public Id: number;
  public Nome: string;
  public Categorias: Categoria[];
}

class Categoria {
  public Id: number;
  public Quatidade: number;
  public Nome: string;
  public PrecoListagem: number;
  public PrecoNet: number;
}