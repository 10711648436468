import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { ServicoOfflineTipo } from '../../model';
import { PerguntasPorTipo } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class ServicoOfflineTipoService{

  constructor(private http: HttpClient){}


  buscarPorParametro(param: string, pagina: Number): Observable<any>{
    const headers = new HttpHeaders().set('noloader', 't');

    if (param.length > 0){
      return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicotipo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
    }else{
      return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicotipo?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
    }
  }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicotipo/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlineservicotipo/${id}`);
    }

    salvar(offlineservicotipo: ServicoOfflineTipo): Observable<any>{
      if (offlineservicotipo.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/offlineservicotipo/${offlineservicotipo.Id}`, offlineservicotipo);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/offlineservicotipo/`, offlineservicotipo);
      }

    }
    //Perguntas do Serviço
    buscarPorParametroPerguntasPorTipo(param: string, pagina: Number): Observable<any>{

      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/perguntasportipo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/perguntasportipo?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }
    }

    buscarPorPerguntasPorTipoId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/perguntasportipo/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    removerPerguntasPorTipo(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/perguntasportipo/${id}`);
    }

    salvarPerguntasPorTipo(servicoofflinetipo: PerguntasPorTipo): Observable<any>{
      if (servicoofflinetipo.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/perguntasportipo/${servicoofflinetipo.Id}`, servicoofflinetipo);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/perguntasportipo/`, servicoofflinetipo);
      }

    }

  }
