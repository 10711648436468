import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { GERENCIAMENTO, ITENSPORPAGINA, API } from '../../app.config';

@Injectable()
export class RiuService {

  constructor(private http: HttpClient) { }

  getPaisesDestinos(): Observable<any> {
    return this.http.get<any>(`${API}/api/riu/lista/paises/destinos`);
  }

  importarHoteis(destinoId: number, cidadeId: number, removerHoteis: boolean): Observable<any> {
    return this.http.get<any>(`${API}/api/riu/importar/hoteis/${destinoId}/${cidadeId}/${removerHoteis}`);
  }
}
