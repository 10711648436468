import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BuscarCasaRQ } from '../../model';

import { API } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class CasaService {
  constructor(private http: HttpClient) { }

  buscarCasa(busca: BuscarCasaRQ, pagina: number): Observable<any> {
    return this.http.post<any>(`${API}/api/Casa/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  detalhes(casa: any, agenciaId : any): Observable<any> {
    return this.http.patch<any>(`${API}/api/casa/detalhes/${agenciaId}`, casa);
  }

  removerCasa(casa: any): Observable<any> {
    return this.http.delete<any>(`${API}/api/Casa/${casa.Id}`);
  }

  buscaCasaPorId(id): Observable<any> {
      return this.http.get<any>(`${API}/api/Casa/${id}`, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  reservarCasa(casa: any): Observable<any> {
    return this.http.post<any>(`${API}/api/casa/reservar/${casa.Id}`, casa, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  alterarCasa(alteracao: any): Observable<any> {
    return this.http.put<any>(`${API}/api/Casa/${alteracao.Casa.Id}`, alteracao, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  cancelarCasa(casa: any): Observable<any> {
      return this.http.patch<any>(`${API}/api/casa/cancelar/${casa.ReservaId}/${casa.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
  }

}
