import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';


import { Reserva, ReservaPagamento, Financiamento, FiltroReserva } from '../../model/';

import { TURISMO, ITENSPORPAGINA, API,API_RELATORIOS } from '../../app.config';
import moment from 'moment';

@Injectable()
export class ReservaService {
    constructor(private http: HttpClient) { }

    adicionarItemOrcamento(reserva: Reserva, id: number): Observable<any> {
        return this.http.patch<any>(`${TURISMO}/api/Reserva/${id}`, reserva);
    }

    alterarOrcamento(reserva: Reserva, mantencao: boolean = false): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/Reserva/${reserva.Id}/${mantencao}`, reserva);
    }

    salvarOrcamento(reserva: Reserva): Observable<any> {
        return this.http.post<any>(`${TURISMO}/api/Reserva/`, reserva);
    }



    buscarMeusOrcamentos(filtro: FiltroReserva, pagina: number): Observable<any> {
        return this.http.post<any>(`${TURISMO}/api/reservas-minhas?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, filtro);
    }

    buscarOrcamento(id: number, mantencao: boolean = false): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/Reserva/${id}/${mantencao}`);
    }

    qrCodePix(id: number): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/reserva/pagamento/qrcode/${id}`);
    }

    GetSlip(id: number): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/Reserva/slip/${id}`);
    }


    buscaReservaBusca(id: number): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/reservas/pesquisa/${id}`);
    }

    salvarDadosPessoas(pessoas: any): Observable<any> {
        let id = pessoas[0].ReservaId;
        return this.http.post<any>(`${TURISMO}/api/reservas/paxes/${id}`, pessoas);
    }

    ImportarDadosPessoas(reservaId, reservaIdImportar): Observable<any> {
        return this.http.post<any>(`${TURISMO}/api/reservas/importarpaxes/${reservaId}/${reservaIdImportar}`, {});
    }

    buscarConsultor(consultor): Observable<any> {

        const headers = new HttpHeaders().set('noloader', 't');

        return this.http.get<any>(`${TURISMO}/api/usuario/consultor/${consultor}`, { headers: headers });
    }

    alterarConsultor(reservaId, consultorId): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/reserva/consultor/${reservaId}/${consultorId}`, {});
    }

    alterarAgente(reservaId, agenteId): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/reserva/agente/${reservaId}/${agenteId}`, {});
    }

    buscarPagamentos(id): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        return this.http.get<any>(`${TURISMO}/api/reservas/pagamentos/${id}`, { headers: headers });
    }

    verificarPodePagar(reservaId): Observable<any> {

        return this.http.get<any>(`${TURISMO}/api/reserva/verificar-pagar/${reservaId}`);
    }

    enviarPagamentos(pagamentos, reservaId): Observable<any> {
        return this.http.post<any>(`${TURISMO}/api/reservas/pagamentos/${reservaId}`, pagamentos);
    }

    estornarPagamento(pagamentoId): Observable<any> {

        return this.http.delete<any>(`${TURISMO}/api/reservas/pagamentos/${pagamentoId}`);
    }

    consultarPagamento(pagamentoId): Observable<any> {

        return this.http.get<any>(`${TURISMO}/api/reservas/pagamentos/consultar/${pagamentoId}`);
    }

    consultarPagamentoPayPal(orderId, pagamentoId): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/Pagamento/Consultar/PayPal/${orderId}/${pagamentoId}`);
    }



    buscaReservaSemLiberaVoucher(param: string, pagina: Number, agenciaId: Number, reservaId: number) {
        const headers = new HttpHeaders().set('noloader', 't');

        if (param.length == 0 && (agenciaId == null || agenciaId == 0) && (reservaId == null || reservaId == 0)) {
            return this.http.get<any>(`${TURISMO}/api/reservas/reservassemliberavoucher?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
        else {
            return this.http.get<any>(`${TURISMO}/api/reservas/reservassemliberavoucher/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&AgenciaId=${agenciaId}&ReservaId=${reservaId}`, { headers: headers });
        }
    }

    LiberarVoucher(id: number): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/reserva/${id}/liberarvoucher`, {});
    }

    LiberarPagamento(id: number): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/reserva/${id}/liberarpagamento`, {});
    }

    DuplicarReservaPax(id: number): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/reserva/${id}/duplicarreservapax`, {});
    }

    urlOrcamento(reservaID, simplificado): string {
        return `${TURISMO}/api/orcamento/${simplificado}/${reservaID}`;
        //return this.http.get<any>(`${TURISMO}/api/orcamento//${reservaID}`);
    }

    exibirOrcamento(reservaID, simplificado, usarComoBlob: boolean = false): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        return this.http.get<any>(`${API}/api/orcamento/${simplificado}/${reservaID}`, { headers: headers, responseType: 'blob' as 'json' })
            .map(res => {
                if (usarComoBlob) {
                    return res;
                } 

                return new Blob([res], { type: 'application/pdf', });
            });
    }

    exibirOrcamentoHtml(reservaID, simplificado): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.http.get<any>(`${API}/api/orcamento/${simplificado}/${reservaID}?html=true`, { headers, responseType: 'text' as 'json' });
    }

    enviarOrcamento(id, simplificado, email): Observable<any> {
        return this.http.patch<any>(`${TURISMO}/api/OrcamentoEnvio/${id}/${simplificado}?html=1`, { Email: email });
    }

    solicitarDesconto(desconto): Observable<any> {
        return this.http.post<any>(`${TURISMO}/api/reserva/${desconto.ReservaId}/solicitar-desconto`, desconto);
    }

    aprovarDesconto(desconto): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/reserva/${desconto.Id}/aprovar-desconto`, desconto);
    }

    recusarDesconto(desconto): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/reserva/${desconto.Id}/recusar-desconto`, desconto);
    }
     removerDesconto(id: Number): Observable<any> {
        return this.http.delete<any>(`${TURISMO}/api/removerdesconto/${id}`);
    }

    buscarReservaPagamentoCartao(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');

        if (param.length > 0) {
            return this.http.get<any>(`${TURISMO}/api/reservas/pagamentos/buscareservapagamentoscartoes/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
        else {
            return this.http.get<any>(`${TURISMO}/api/reservas/pagamentos/buscareservapagamentoscartoes?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }

    buscarReservaPagamentoPorId(id: number): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/reservas/pagamentos/buscareservapagamentoporid/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    GeradorChave(pagamentoId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/contrato/criar/chave/${pagamentoId}`);
    }

    GeradorChavePagante(paganteId: number, reservaId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/contrato/criar/chave/${paganteId}/${reservaId}`);
    }

    GeradorChaveLink(pagamentoId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/contrato/criar/link/${pagamentoId}`);
    }

    GeradorChaveLinkPagante(paganteId: number, reservaId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/contrato/criar/link/${paganteId}/${reservaId}`);
    }

    ListaPagantes(reservaId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/contrato/lista-pagantes/${reservaId}`);
    }

    GeradorLinkCartao(pagamentoId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/contrato/criar/link-cartao/${pagamentoId}`);
    }

    EnviaLinkCartao(pagamentoId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/contrato/envia/link-cartao/${pagamentoId}`);
    }

    GeradorChaveLinkPDF(pagamentoId: number): Observable<any> {
        return this.http.get<any>(`${API}/api/contrato/criar/linkPDF/${pagamentoId}`);
    }
    alterarReservaPagamento(reservapagamento: ReservaPagamento): Observable<any> {
        return this.http.put<any>(`${API}/api/reservas/pagamentos/alterar/${reservapagamento.Id}`, reservapagamento);
    }

    aprovarFinanciamentoTerceiros(pagamentoFinanciamento: Financiamento): Observable<any> {
        return this.http.put<any>(`${API}/api/reserva/pagamentos/aprovarfinanciamentoterceiro`, pagamentoFinanciamento);
    }

    buscaTermometro(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/reservas/termometro/${id}`);
    }

    RelatorioPix(DataInicial: any, DataFinal: any): Observable<any> {
        return this.http.post<any>(`${API_RELATORIOS}/api/reservas/pagamentos-pix`, { DataInicial: DataInicial, DataFinal: DataFinal });
    }
}
