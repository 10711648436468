import { BloqueioAereoVoo } from "../../model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";

import { GERENCIAMENTO, ITENSPORPAGINA } from "../../app.config";

@Injectable()
export class BloqueioAereoVooService {
  constructor(private http: HttpClient) {}

  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set("noloader", "t");
    if (param.length > 0)
    {
      return this.http.get<any>(`${GERENCIAMENTO}/api/bloqueioaereovoo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,{ headers: headers });
    }
    else
    {
      return this.http.get<any>(`${GERENCIAMENTO}/api/bloqueioaereovoo?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,{ headers: headers });
    }
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/bloqueioaereovoo/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/bloqueioaereovoo/${id}`);
  }

  salvar(bloqueioAereoVoo: BloqueioAereoVoo): Observable<any> {
    if (bloqueioAereoVoo.Id > 0)
    {
      return this.http.put<any>(`${GERENCIAMENTO}/api/bloqueioaereovoo/${bloqueioAereoVoo.Id}`, bloqueioAereoVoo);
    }
    else
    {
      return this.http.post<any>(`${GERENCIAMENTO}/api/bloqueioaereovoo/`, bloqueioAereoVoo);
    }
  }
}
