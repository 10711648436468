import { Component, OnInit, Input, ContentChild, AfterContentInit } from '@angular/core';
import { NgModel, FormControlName  } from '@angular/forms';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})

export class InputComponent implements OnInit, AfterContentInit {

  input: any;
  @Input() label: string;
  @Input() errorMessage: string = "Dados inválidos";
  @Input() requiredMessage: string = "Preenchimento obrigatório";
  @Input() showTip: boolean = false;
  @Input() modelo: string = '';


  @ContentChild(NgModel) model: NgModel;
  @ContentChild(FormControlName) control: FormControlName;


  constructor() { }

  ngAfterContentInit(){
    this.input = this.model || this.control;
    if (this.input === undefined)
      throw new Error(this.label + ': Este componente precisa ser usado com ngModel ou FormControlName');
  }

  ngOnInit() {
  }

  hasSuccess(): boolean{
    return this.input.valid && (this.input.dirty || this.input.touched);
  }

  hasError(): boolean{
    return this.input.invalid && (this.input.dirty || this.input.touched);
  }
}
