import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API, ITENSPORPAGINA } from '../../app.config';
import { OfflineCarro, OfflineCarroCategoria, OfflineCarroCategoriaDisponibilidade, OfflineCarroCategoriaTarifa, OfflineCarroCombustivel, OfflineCarroManutencaoPeriodo, OfflineCarroPromocaoTarifa, OfflineCarroProtecao, OfflineCarroTipo, OfflineCarroTracao, OfflineCarroTransmissao, OfflineLojasCarroPickup } from '../../model';

const headers = new HttpHeaders().set('noloader', 't');

@Injectable()
export class OfflineCarroService {

  constructor(private http: HttpClient) { }

  //OFFLINECARRO
  buscarCarroPorPorParametro(param: any, ativo: boolean = true, pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarro/Lista/${param}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&Ativo=${ativo}`, { headers: headers });
  }
  buscarCarroPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarro/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }
  removerCarro(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarro/${id}`);
  }
  salvarCarro(CarroOffline: OfflineCarro): Observable<any> {
    if (CarroOffline.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarro/${CarroOffline.Id}`, CarroOffline);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarro/`, CarroOffline);
    }
  }
  RelatorioDeCarros(id) {
    return this.http.get<any>(`${API}/api/OfflineCarro/Relatorio/${id}`, { headers: headers });
  }


  //VINCULAR WEBSERVICES
  vincularWebService(id: number, WebserviceId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/OfflineCarro-Webservice/Vincular/${id}/${WebserviceId}`, {});
  }

  desvincularWebService(id: number, WebserviceId: number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarro-Webservice/Vincular/${id}/${WebserviceId}`, {});
  }

  // VINCULAR AGENCIAS
  vincularAgenciaExclusiva(id: number, agenciaId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/OfflineCarro-Agencia/Vincular/${id}/${agenciaId}`, { headers: headers });
  }
  desvincularAgenciaExclusiva(id: number, agenciaId: number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarro-Webservice/Desvincular/${id}/${agenciaId}`, { headers: headers });
  }

  //VINCULAR LOJAS
  vincularLojaCarro(id: number, LojaCarroId: number): Observable<any> {
    return this.http.post<any>(`${API}/api/OfflineCarro-LojaCarro/Vincular/${id}/${LojaCarroId}`, { headers: headers });
  }
  desvincularLojaCarro(id: number, LojaCarroId: number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarro-LojaCarro/Desvincular/${id}/${LojaCarroId}`, { headers: headers });
  }

  //VINCULAR LOJAS PICKUP
  vincularLojaCarroPickup(id: number, LojaCarroPickup: OfflineLojasCarroPickup): Observable<any> {
    return this.http.post<any>(`${API}/api/OfflineCarro-LojaCarroPickup/Vincular/${id}`, LojaCarroPickup);
  }
  desvincularLojaCarroPickup(id: number, LojaCarroPickup: OfflineLojasCarroPickup): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarro-LojaCarroPickup/Desvincular/${id}/${LojaCarroPickup.Id}`, { headers: headers });
  }
  salvarLojaCarroPickup(LojaCarroPickup: OfflineLojasCarroPickup): Observable<any> {
    if (LojaCarroPickup.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroLojaCarroPickup/${LojaCarroPickup.Id}`, LojaCarroPickup);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroLojaCarroPickup/`, LojaCarroPickup);
    }
  }

  //ADICIONAR IMAGENS
  alterarImagemPrincipal(arquivo: any, id: number): Observable<any> {
    const url = `${API}/api/OfflineCarro/ImagemPrincipal/${id}/`;
    const formData = new FormData();
    formData.append('Arquivo', arquivo);
    return this.http.post(url, formData, { headers: headers });
  }

  uploadImagens(arquivo: any, id: number, descricao = "Sem Descrição"): Observable<any> {
    const url = `${API}/api/OfflineCarro/Imagem/${id}/`;
    const formData = new FormData();
    formData.append('Arquivo', arquivo);
    formData.append('Descricao', descricao);
    return this.http.post(url, formData, { headers: headers });
  }
  removerImagem(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarro/Imagem/Remover/${id}`);
  }


  //COMBUSTIVEL
  buscarCombustivelPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroCombustivel/${id}?Pagina=${1}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  buscarCombustivelPorParametro(param: any, pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroCombustivel/Lista/${param}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  removerCombustivel(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroCombustivel/${id}`);
  }
  salvarCombustivel(Combustivel: OfflineCarroCombustivel): Observable<any> {
    if (Combustivel.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroCombustivel/${Combustivel.Id}`, Combustivel);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroCombustivel/`, Combustivel);
    }
  }

  //TRACAO
  buscarTracaoPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroTracao/${id}?Pagina=${1}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  buscarTracaoPorParametro(param: any, pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroTracao/Lista/${param}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  removerTracao(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroTracao/${id}`);
  }
  salvarTracao(Tracao: OfflineCarroTracao): Observable<any> {
    if (Tracao.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroTracao/${Tracao.Id}`, Tracao);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroTracao/`, Tracao);
    }
  }

  //TRANSMISSAO
  buscarTransmissaoPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroTransmissao/${id}?Pagina=${1}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  buscarTransmissaoPorParametro(param: any, pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroTransmissao/Lista/${param}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  removerTransmissao(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroTransmissao/${id}`);
  }
  salvarTransmissao(Transmissao: OfflineCarroTransmissao): Observable<any> {
    if (Transmissao.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroTransmissao/${Transmissao.Id}`, Transmissao);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroTransmissao/`, Transmissao);
    }
  }

  //TIPO
  buscarTipoPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroTipo/${id}?Pagina=${1}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  buscarTipoPorParametro(param: any, pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroTipo/Lista/${param}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  removerTipo(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroTipo/${id}`);
  }
  salvarTipo(Tipo: OfflineCarroTipo): Observable<any> {
    if (Tipo.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroTipo/${Tipo.Id}`, Tipo);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroTipo/`, Tipo);
    }
  }

  //CATEGORIA
  buscarCategoriaPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroCategoria/${id}?Pagina=${1}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  buscarCategoriaPorParametro(param: any, pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroCategoria/Lista/${param}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  removerCategoria(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroCategoria/${id}`);
  }
  salvarCategoria(Categoria: OfflineCarroCategoria): Observable<any> {
    if (Categoria.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroCategoria/${Categoria.Id}`, Categoria);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroCategoria/`, Categoria);
    }
  }

  //Categoria-Tarifa
  buscarCategoriaTarifaPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroCategoriaTarifa/${id}?Pagina=${1}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  buscarCategoriaTarifaPorParametro(param: any, pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroCategoriaTarifa/Lista/${param}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
  }
  removerCategoriaTarifa(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroCategoriaTarifa/${id}`);
  }
  salvarCategoriaTarifa(CategoriaTarifa: OfflineCarroCategoriaTarifa): Observable<any> {
    if (CategoriaTarifa.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroCategoriaTarifa/${CategoriaTarifa.Id}`, CategoriaTarifa);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroCategoriaTarifa/`, CategoriaTarifa);
    }
  }

  //Categoria-Disponibilidade
  removerCategoriaDisponibilidade(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroCategoriaDisponibilidade/${id}`);
  }
  salvarCategoriaDisponibilidade(offlineCarroCategoriaDisponibilidade: OfflineCarroCategoriaDisponibilidade[], DisponibilidadePeriodoAte: any, DisponibilidadePeriodoDe: any): Observable<any> {
    return this.http.post<any>(`${API}/api/OfflineCarroCategoriaDisponibilidade`, { Disponibilidades: offlineCarroCategoriaDisponibilidade, DisponibilidadePeriodoAte: DisponibilidadePeriodoAte, DisponibilidadePeriodoDe: DisponibilidadePeriodoDe });
  }

  ListarDisponibilidadePeriodo(OfflineCarroCategoriaId: Number, pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroCategoriaDisponibilidadePeriodo/Lista/${OfflineCarroCategoriaId}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  MontaCalendarioDisponibilidadeCarros(categoriaId: number, ano: number, mes: number, periodo: number): Observable<any> {
    return this.http.post<any>(`${API}/api/OfflineCarroCategoriaDisponibilidade/ListaDisponibilidade/?CategoriaId=${categoriaId}&Ano=${ano}&Mes=${mes}&Periodo=${periodo}`, { headers: headers });
  }
  MontaCalendarioDisponibilidadePeriodoCarros(categoriaId: number, de: any, ate: any): Observable<any> {
    return this.http.post<any>(`${API}/api/OfflineCarroCategoriaDisponibilidade/ListaDisponibilidade/?CategoriaId=${categoriaId}&De=${de}&Ate=${ate}`, { headers: headers });
  }

  //PromocaoTarifa
  buscarPorParametroPromocaoTarifa(param: string, pagina: Number): Observable<any> {
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/OfflineCarroPromocaoTarifa/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/OfflineCarroPromocaoTarifa?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }
  buscarPorIdPromocaoTarifa(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroPromocaoTarifa/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  removerPromocaoTarifa(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroPromocaoTarifa/${id}`);
  }

  salvarPromocaoTarifa(offlineCarroCategoriaTarifa: OfflineCarroPromocaoTarifa): Observable<any> {
    if (offlineCarroCategoriaTarifa.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroPromocaoTarifa/${offlineCarroCategoriaTarifa.Id}`, offlineCarroCategoriaTarifa);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroPromocaoTarifa/`, offlineCarroCategoriaTarifa);
    }
  }

  //ManutencaoPeriodo
  buscarPorParametroManutencaoPeriodo(param: string, pagina: Number): Observable<any> {
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/OfflineCarroManutencaoPeriodo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/OfflineCarroManutencaoPeriodo?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }
  buscarPorIdManutencaoPeriodo(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroManutencaoPeriodo/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }
  removerManutencaoPeriodo(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroManutencaoPeriodo/${id}`);
  }
  salvarManutencaoPeriodo(offlineCarroManutencaoPeriodo: OfflineCarroManutencaoPeriodo): Observable<any> {
    if (offlineCarroManutencaoPeriodo.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroManutencaoPeriodo/${offlineCarroManutencaoPeriodo.Id}`, offlineCarroManutencaoPeriodo);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroManutencaoPeriodo/`, offlineCarroManutencaoPeriodo);
    }
  }

  //OfflineCarroProtecao
  buscarPorParametroProtecao(param: string, pagina: Number): Observable<any> {
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/OfflineCarroProtecao/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/OfflineCarroProtecao?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }
  buscarPorIdProtecao(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/OfflineCarroProtecao/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }
  removerProtecao(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineCarroProtecao/${id}`);
  }
  salvarProtecao(offlineCarroProtecao: OfflineCarroProtecao): Observable<any> {
    if (offlineCarroProtecao.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineCarroProtecao/${offlineCarroProtecao.Id}`, offlineCarroProtecao);
    } else {
      return this.http.post<any>(`${API}/api/OfflineCarroProtecao/`, offlineCarroProtecao);
    }
  }
}
