import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';


//import { Agencia } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA ,TURISMO_HOTEL} from '../../app.config';

@Injectable()
export class PalladiumService {

  constructor(private http: HttpClient) { }

  buscarPorParametro(codigofornecedor: string,localizador: string,tipodata: string,datain: string,dataout: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');

    if (codigofornecedor.length > 0) {
      return this.http.post<any>(`${GERENCIAMENTO}/api/Palladium/list/?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,{ codigofornecedor: codigofornecedor,localizador:localizador,tipodata:tipodata,datain:datain,dataout:dataout});
    }
  }

  buscahotelcachepalladium(): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/Palladium/`);
  }


  cancelarHotel(hotel: any): Observable<any>{
    return this.http.patch<any>( `${GERENCIAMENTO}/api/Palladium/cancelar/${hotel.reserva}/${hotel.hotelid}`, {},{headers: new HttpHeaders().set('noloader', 'true')});
  }
}
