import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { FormaPagamento } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class FormaPagamentoService{

  constructor(private http: HttpClient){}

  buscarTodas(): Observable<any>{
    let headers = new HttpHeaders().set('noloader', 't');

    return this.http.get<any>( `${GERENCIAMENTO}/api/FormaPagamento?Pagina=1&ItensPorPagina=100`, {headers: headers});

  }

  buscarPorParametro(param: string, pagina: Number): Observable<any>{
    let headers = new HttpHeaders().set('noloader', 't');

    if (param.length > 0)
    {
      return this.http.get<any>( `${GERENCIAMENTO}/api/forma-pagamento/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
    }
    else
    {
      return this.http.get<any>( `${GERENCIAMENTO}/api/FormaPagamento?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
    }
  }

  buscarPorId(id: number): Observable<any>{
    return this.http.get<any>( `${GERENCIAMENTO}/api/FormaPagamento/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable<any>{
    return this.http.delete<any>( `${GERENCIAMENTO}/api/FormaPagamento/${id}`);
  }

  salvar(FormaPagamento: FormaPagamento): Observable<any>{
    if (FormaPagamento.Id > 0){
      return this.http.put<any>( `${GERENCIAMENTO}/api/FormaPagamento/${FormaPagamento.Id}`, FormaPagamento);
    }else{
      return this.http.post<any>( `${GERENCIAMENTO}/api/FormaPagamento/`, FormaPagamento);
    }

  }

}
