
export class Cambio {
  Id: number;
  Taxas: CambioTaxa[] = [];
  Multiplica: boolean = true;
  MoedaOrigemID: number;
  MoedaDestinoID: number;
  diferencaTaxa: number;
}

export class CambioTaxa {
  Id: number = 0;
  Cambio: Cambio;
  Taxa: any = 0;
  CambioId: number;
  TaxaAnterior: number;
}

export class Moeda {
  Id: number;
  Nome: any;
  Simbolo: any;
  Sigla: any;


}
