export class Produto {
    public Id: number;
    public ProdutoTipoId: number;
    public Nome: string;
    public Descricao: string;
    public selecionado: boolean;
    public Valor: number;
    public Quantidade: number;
    public ValorVenda: number;  
    public QuantidadeVenda: number;
    public Moeda: any;
    public MoedaId: any;
    public Imagens: ProdutoImagens[];
}

export class ProdutoImagens {
    public Id: number ;
    public ProdutoId: number;
    public Imagem: string;
  }