import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { CacheHotel, Cidade } from '../../model';
import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class HotelBedsService {

    constructor(private http: HttpClient) { }

    importar(cidade: Cidade, webserviceId: number): Observable<any> {
        console.log("Entrou");
        let data = {
            cidade,
            webserviceId
        };
        return this.http.post<any>(`${GERENCIAMENTO}/api/HotelBeds/`, data);
    }

    listarZones(cidade: Cidade, WebserviceId: number): Observable<any> {
        return this.http.post<any>(`${GERENCIAMENTO}/api/HotelBeds/listaZones/` + WebserviceId, cidade)
    }

    listarWebservices(): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}/api/HotelBeds/listaWebservices/`)
    }

}
