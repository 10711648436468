// canLoad controla o carregamento do módulo

import { CanLoad, Route, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate  } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Observable } from 'rxjs/Observable';
import { USA_TURISMO } from '../app.config'

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class LoggedInGuard implements CanLoad, CanActivate, CanDeactivate<ComponentCanDeactivate>{

  permissoes = [];
  isRoot = false;
  usuarioLogado : any;


  constructor(private loginService: LoginService){

    this.getPermissions();

    this.usuarioLogado = this.loginService.user();
  }


  getPermissions(){
    const permi = window.localStorage.getItem('gy84d8');

    if (permi != null) {
      this.permissoes = JSON.parse(atob(permi));

      if (this.permissoes.indexOf('root') > -1) {
        this.isRoot = true;
      }
    }
  }

  checkAuthentication(path: string): boolean{
    const loggedin = this.loginService.isLoggedIn();
    if (!loggedin){
      this.loginService.handleLogin(`/${path}`);
    }
    return loggedin;
  }


  checkPermission(permissoesNecessarias): boolean{
    if (this.isRoot || !permissoesNecessarias || permissoesNecessarias.permissao == null){
      return true;
    }

    let retorno = false;

    if(this.permissoes.length == 0){
      this.getPermissions();
    }

    if (permissoesNecessarias.permissao != null){
        retorno = permissoesNecessarias.permissao.some((element, index, array) => this.existeNasPermissoes(element, index, array));
    }
    if(!retorno)
      alert("Você não possui credenciais necessárias para acessar esse endereço!");
    return retorno;

  }

  canLoad(route: Route): boolean{
    return this.checkAuthentication(route.path) ;
  }

canActivate(
  activatedRoute: ActivatedRouteSnapshot,
  routerStateSnapshot: RouterStateSnapshot): boolean{

    return this.checkAuthentication(activatedRoute.routeConfig.path) && this.checkPermission(activatedRoute.data);
  }

  existeNasPermissoes(element, index, array){
    return (this.permissoes.indexOf(element) > -1);
  }


  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
  return component.canDeactivate() ?
    true :
    confirm('Aviso: Selecione pelo menos um perfil para este usuário !! Pressione "Cancelar" para voltar e realizar as alterações, ou OK para sair.');
  }

  }


@Injectable()
export class UsaTurismo implements CanLoad, CanActivate {

  usaTurismo = USA_TURISMO;

  constructor() { }



  canLoad(route: Route): boolean {
    return this.usaTurismo;
  }

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot): boolean {

    return this.usaTurismo;
  }



}
