import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { LoginService } from './login.service';


import { API, ITENSPORPAGINA } from '../app.config';
import { CLIENT_ID } from '../app.config';

@Injectable()
export class DashboardService{
  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService){}


    GetVendasSemana(): Observable<any>{
        return this.http.get<any>( `${API}/api/dashboard/getvendassemana`);
    }

    GetVendasMensais(): Observable<any>{
        return this.http.get<any>( `${API}/api/dashboard/getvendasmensais`);
    }

    GetVendasPendentes(): Observable<any>{
        return this.http.get<any>( `${API}/api/dashboard/getvendaspendentes`);
    }

    GetStatusProdutos(): Observable<any>{
        return this.http.get<any>( `${API}/api/dashboard/getstatusprodutos`);
    }

    GetAgenciaInativas(pagina: Number): Observable<any>{
        return this.http.get<any>( `${API}/api/dashboard/getagenciasinativas?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    GetRankingConversao(): Observable<any>{
        return this.http.get<any>( `${API}/api/dashboard/getrankingconversao`);
    }


  }
