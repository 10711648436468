import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
//import { Router } from '@angular/router';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class ReservaGestaoService {
  headers = new HttpHeaders().set('noloader', 't');
  constructor(private http: HttpClient) { }

  buscarTotalItensRevisar(): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/reservagestao/totalitensrevisar`, { headers: this.headers });
  }

  buscarAereosRevisar(pagina: Number, empresaId: string): Observable<any> {
    let headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${GERENCIAMENTO}/api/reservagestao/aereosrevisar?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&empresaId=${empresaId}`, { headers: this.headers });
  }

  buscarHoteisRevisar(pagina: Number, empresaId: string): Observable<any> {
    let headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${GERENCIAMENTO}/api/reservagestao/hoteisrevisar?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&empresaId=${empresaId}`, { headers: this.headers });
  }

  buscarServicosRevisar(pagina: Number, empresaId: string): Observable<any> {
    let headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${GERENCIAMENTO}/api/reservagestao/servicosrevisar?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&empresaId=${empresaId}`, { headers: this.headers });
  }

  buscarCarrosRevisar(pagina: Number, empresaId: string): Observable<any> {
    let headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${GERENCIAMENTO}/api/reservagestao/carrosrevisar?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&empresaId=${empresaId}`, { headers: this.headers });
  }

}
