import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { ServicoOfflineFornecedor } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class ServicoOfflineFornecedorService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{

      const headers = new HttpHeaders().set('noloader', 't');

      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicofornecedor/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicofornecedor?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicofornecedor/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlineservicofornecedor/${id}`);
    }

    salvar(servicoofflinefornecedor: ServicoOfflineFornecedor): Observable<any>{
      if (servicoofflinefornecedor.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/offlineservicofornecedor/${servicoofflinefornecedor.Id}`, servicoofflinefornecedor);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/offlineservicofornecedor/`, servicoofflinefornecedor);
      }

    }

  ativarTodos(id: number): Observable<any> {
      return this.http.put<any>(`${GERENCIAMENTO}/api/offlineservicofornecedor/${id}/ativar-todos`, {});    
  }
  inativarTodos(id: number): Observable<any> {
    return this.http.put<any>(`${GERENCIAMENTO}/api/offlineservicofornecedor/${id}/inativar-todos`, {});    
  }

  }
