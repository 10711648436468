import { Reserva } from '../turismo/reserva.model';
import { Agencia } from './agencia.model';

export class GrupoEconomico {
  public Id: number;

  public Nome: String;
  public Descricao: String;
  public Agencias: Agencia[];
  public Reservas: Reserva[];
  public Alteracoes: any;
  public Situacao: any;
  public usuarioLogado: any;
  
}
