import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";

import { TeleVendasPagamento } from "../../model";

import { GERENCIAMENTO, ITENSPORPAGINA,API_RELATORIOS } from "../../app.config";

@Injectable()
export class TeleVendasPagamentoService {
  constructor(private http: HttpClient) { }

  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set("noloader", "t");

    if (param.length > 0) {
      return this.http.get<any>(
        `${GERENCIAMENTO}/api/televendaspagamento/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,
        { headers: headers }
      );
    } else {
      return this.http.get<any>(
        `${GERENCIAMENTO}/api/televendaspagamento?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`,
        { headers: headers }
      );
    }
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(
      `${GERENCIAMENTO}/api/televendaspagamento/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`
    );
  }

  pagar(
    televendaspagamento: TeleVendasPagamento,
    paxPaganteId?: number
  ): Observable<any> {
    if (paxPaganteId > 0 && paxPaganteId != null) {
      return this.http.post<any>(
        `${GERENCIAMENTO}/api/televendaspagamento/pagar/${paxPaganteId}`,
        televendaspagamento
      );
    } else {
      return this.http.post<any>(
        `${GERENCIAMENTO}/api/televendaspagamento/pagar/`,
        televendaspagamento
      );
    }
  }

  cancelar(id: number): Observable<any> {
    return this.http.put<any>(
      `${GERENCIAMENTO}/api/televendaspagamento/cancelar/${id}`,
      {}
    );
  }

  consultarStatus(id: number): Observable<any> {
    return this.http.get<any>(
      `${GERENCIAMENTO}/api/televendaspagamento/consultar/${id}`
    );
  }

  gerarLink(registro: TeleVendasPagamento, tipo: number): Observable<any> {
    return this.http.post<any>(
      `${GERENCIAMENTO}/api/televendaspagamento/gerarlink/${tipo}`,
      registro
    );
  }

  consultaLink(id: number): Observable<any> {
    return this.http.get<any>(
      `${GERENCIAMENTO}/api/televendaspagamento/consultalink/${id}`
    );
  }

  relatorioCartoesAprovados(dataInicial: string, dataFinal: string): Observable<any> {
    return this.http.get<any>(`${API_RELATORIOS}/api/RelatorioCartoesAprovados?DataInicial=${dataInicial}&DataFinal=${dataFinal}`);
  }
}
