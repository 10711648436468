import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tablefilter'
})

export class TablefilterPipe implements PipeTransform {

  transform(list: any[], attributeName: string, value: string) {

    if (!attributeName || !value || value == "") {
      return list; // Retorna a lista sem filtrar se attributeName ou value forem nulos ou vazios
    }

    return list.filter(item => item[attributeName] == value);
  }

  //forma de uso: nesse exemplo a baixo retornaria apenas os females
  //   <div *ngFor="let item of itemList | tablefilter:'gender':'female'">
  //      <td>{{ user.name }}</td>
  //      <td>{{ user.gender }}</td>
  //  </div>

  // <div *ngFor="let item of itemList | tablefilter:''">
  //   <!-- Exibe todos os itens da lista -->
  // </div>

  // <div *ngFor="let item of itemList | tablefilter:'gender':''">
  //   <!-- Filtra apenas pelo sub-atributo 'gender' -->
  // </div>

  // <div *ngFor="let item of itemList | tablefilter:'nome':'João'">
  //   <!-- Filtra pelo sub-atributo 'nome' com valor igual a 'João' -->
  // </div>

  // @Pipe({
  //   name: 'tablefiltersub-item'
  // })
  // export class TablefilterPipe implements PipeTransform {
  //   transform(list: any[], attributeName: string, value: string) {
  //     if (!attributeName || !value) {
  //       return list; // Retorna a lista sem filtrar se attributeName ou value forem nulos ou vazios
  //     }
      
  //     return list.filter(item => this.getPropertyValue(item, attributeName) === value);
  //   }

  //   private getPropertyValue(object: any, path: string): any {
  //     const properties = path.split('.');
  //     let value = object;
  //     for (const prop of properties) {
  //       value = value[prop];
  //       if (value === undefined) {
  //         return undefined;
  //       }
  //     }
  //     return value;
  //   }
  // }
  // Dessa forma, o pipe irá percorrer o caminho 'subitem.propriedade',
  //  acessar a propriedade propriedade dentro de subitem em cada item do
  //   array itemList e comparar seu valor com 'valor'. Isso permite filtrar
  //    com base em um subitem dentro do item.
}
