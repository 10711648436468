import { Aereo } from './aereo.model';
import { Hotel } from './hotel.model';
import { Servico } from './servico.model';
import { Carro } from './carro.model';
import { Casa } from './casa.model';
import { Seguro } from './seguro.model';
import { Produto } from './produto.model';
import { PacoteReserva } from '../gerenciamento/pacote.model';
import { Circuito } from './circuito.model';
import { BetoCarrero } from './beto-carrero.model';

export class Orcamento {
    Id: number = 0;
    Aereos: Aereo[] = [];
    Hoteis: Hotel[] = [];
    Circuitos: Circuito[] = [];
    Transfer: Servico[] = [];
    Servicos: Servico[] = [];
    Ticket: Servico[] = [];
    Carros: Carro[] = [];
    Seguros: Seguro[] = [];
    Casas: Casa[] = [];
    Produtos: Produto[] = [];
    PacoteReservas: PacoteReserva[] = [];
    BetoCarreroIngressos: BetoCarrero[] = [];
}
