import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { Markup } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class MarkupService {

  constructor(private http: HttpClient) { }


  buscarPorParametro(param: string, pagina: Number, markupespecial?: boolean): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');

    if (!markupespecial) {
      if (param.length > 0) {
        return this.http.get<any>(`${GERENCIAMENTO}/api/Markup/list/${param.trim()}/${false}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
      }
      else {
        return this.http.get<any>(`${GERENCIAMENTO}/api/Markup?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
      }
    }
    else {
      if (param.length > 0) {
        return this.http.get<any>(`${GERENCIAMENTO}/api/Markup/list/${param.trim()}/${markupespecial}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
      }
      else {
        return this.http.get<any>(`${GERENCIAMENTO}/api/Markup/list/${null}/${markupespecial}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
      }
    }
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/Markup/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/Markup/${id}`);
  }

  salvar(markup: Markup): Observable<any> {
    if (markup.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/Markup/${markup.Id}`, markup);
    } else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/Markup/`, markup);
    }

  }

  buscarPorWebService(param: string, pagina: Number, webServiceId: Number, trazerTodos: boolean = false): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${GERENCIAMENTO}/api/Markup/listporwebservice/${webServiceId}/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${trazerTodos ? 9999 : ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${GERENCIAMENTO}/api/Markup/listporwebservice/${webServiceId}?Pagina=${pagina}&ItensPorPagina=${trazerTodos ? 9999 : ITENSPORPAGINA}`, { headers: headers });
    }
  }

  // vinculaPerfilMarkup (id: number, perfilMarkupId : number):Observable<any> {
  // return this.http.post<any>( `${GERENCIAMENTO}/api/markup-perfilmarkup/vincular/${id}/${perfilMarkupId}`, {});
  // }
  //
  // desvincularPerfilMarkup (id: number, perfilMarkupId : number):Observable<any> {
  //   return this.http.post<any>( `${GERENCIAMENTO}/api/markup-perfilmarkup/desvincular/${id}/${perfilMarkupId}`, {});
  // }

}
