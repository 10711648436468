import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay, withLatestFrom, takeWhile } from 'rxjs/operators';
import { UsuarioService, LoginService, Util, CambioService } from '../../../services';
import { Usuario } from '../../../model';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService } from '../../../@core/utils';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  templateUrl: './sample.layout.html',
})
export class SampleLayoutComponent implements OnDestroy {

  usuariolayout: Usuario = new Usuario();

  Logo: string = '';

  agencia: any;

  carregouUsuario: boolean = false;
  dadosCambio: any;


  subMenu: NbMenuItem[] = [
    { title: 'PAGE LEVEL MENU', },
  ];
  layout: any = {};
  sidebar: any = {};

  private alive = true;

  currentTheme: string;

  constructor(
    private modalService: NgbModal,
    protected stateService: StateService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService,
    private loginService: LoginService,
    private usuarioService: UsuarioService,
    private cambioService: CambioService,) {
    this.stateService.onLayoutState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((layout: string) => this.layout = layout);

    this.stateService.onSidebarState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName('is');
    this.menuService.onItemSelect()
      .pipe(
        takeWhile(() => this.alive),
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
      )
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {

        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });

    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
      });
  }

  ngOnInit() {
    this.carregaDadosCambio();

    let agencia = window.localStorage.getItem('g8h7d8');


    if (agencia != null) {
      agencia = JSON.parse(atob(agencia));
      this.agencia = agencia;
      this.Logo = Util.pathImagemApi(this.agencia.Logo)
    };

    let user = window.localStorage.getItem('gs57d8');


    if (user != null) {
      this.usuariolayout = JSON.parse(atob(user));

      if (this.Logo == '') {
        this.Logo = Util.pathImagemApi(this.usuariolayout.Agencia ? this.usuariolayout.Agencia.Logo : '');
      }

      this.carregouUsuario = true;
    }
    else {
      this.usuarioService.getUserById(this.loginService.user().Id).subscribe(
        dados => {

          this.usuariolayout = dados.Items[0];
          //console.log(this.usuariolayout);

          findInvalidCharacter(JSON.stringify(this.usuariolayout));
          
          function findInvalidCharacter(str) {
            let invalids = [];
            for (let i = 0; i < str.length; i++) {
              if (str.charCodeAt(i) > 255) {
                invalids.push(str[i]);
              }
            };
            if(invalids.length > 0){
              console.log("O cadastro do usuário possui o seguintes caracteres inválidos :" + invalids);
            }
          }

          window.localStorage.setItem('gs57d8', btoa(JSON.stringify(this.usuariolayout)));

          if (this.Logo == '') {
            this.Logo = Util.pathImagemApi(this.usuariolayout.Agencia ? this.usuariolayout.Agencia.Logo : '');
          }

          this.carregouUsuario = true;
        });
    }

  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngDoCheck() {
    if ((window.localStorage.getItem('g8h7d8') && !this.agencia) || (window.localStorage.getItem('g8h7d8') && this.agencia && (this.agencia != JSON.parse(atob(window.localStorage.getItem('g8h7d8')))))) {
      let agencia = JSON.parse(atob(window.localStorage.getItem('g8h7d8')));
      this.agencia = agencia;
      this.Logo = Util.pathImagemApi(this.agencia.Logo)
    }
  }

  abrirModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true, size: 'lg' });
  }


  trocarAgencia(agencia) {
    this.agencia = agencia;
    this.Logo = Util.pathImagemApi(this.agencia ? this.agencia.Logo : this.usuariolayout.Empresa.LogoInternet);
    window.localStorage.setItem('g8h7d8', btoa(JSON.stringify(agencia)));
  }

  limparAgencia() {
    delete this.agencia;
    this.Logo = '/assets/img/notfound.jpg';
    window.localStorage.removeItem('g8h7d8');
  }


  carregaDadosCambio() {
    let busca = this.cambioService.buscarPorParametro('', 1);

    busca.subscribe(
      dados => {
        if (dados) {
          this.dadosCambio = dados.Items;
        } else {
          this.dadosCambio = null;
        }
      },
      erro => {
        console.log(erro);
        this.dadosCambio = null;
      }
    );
  }
}
