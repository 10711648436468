import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { ServicoOfflinePergunta } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class ServicoOfflinePerguntaService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{

      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicopergunta/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicopergunta?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/offlineservicopergunta/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/offlineservicopergunta/${id}`);
    }

    salvar(servicoofflinepergunta: ServicoOfflinePergunta): Observable<any>{
      if (servicoofflinepergunta.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/offlineservicopergunta/${servicoofflinepergunta.Id}`, servicoofflinepergunta);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/offlineservicopergunta/`, servicoofflinepergunta);
      }

    }

  }
