import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { CacheHotel, Cidade } from '../../model';
import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class BlackListHotelService {

  constructor(private http: HttpClient) { }

  incluir(NomeHotel: string,CodigoHotel: string, WebServiceId:number): Observable<any> {
    let data = {
      NomeHotel,
      CodigoHotel,
      WebServiceId
    };

    console.log(data);
    return this.http.post<any>(`${GERENCIAMENTO}/api/BlackListHotel`, data);
  }

  buscarPorParametro(param: string, pagina: Number): Observable<any>{
    const headers = new HttpHeaders().set('noloader', 't');
    console.log(param)
    if (param.length <= 0){
      return this.http.get<any>( `${GERENCIAMENTO}/api/BlackListHotel?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
    }else{
      return this.http.get<any>( `${GERENCIAMENTO}/api/BlackListHotel/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
    }
  }

  remover(id: Number): Observable<any>{
    return this.http.delete<any>( `${GERENCIAMENTO}/api/BlackListHotel/${id}`);
  }




}
