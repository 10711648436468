import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';


//import { Agencia } from '../../model';

import { EMPRESAS, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class EzlinkService{

  constructor(private http: HttpClient){}


  buscar(param: string, destino:string): Observable<any>{

      // const headers = new HttpHeaders().set('noloader', 't');    
      if(!destino || destino.length == 0){
        destino = ',' // se for o parametro sem nada não atende e não entra na rota
      }
        
      return this.http.get<any>( `${EMPRESAS}/api/Ezlink/${param}/${destino}`);
      
    }

  }
