import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { MarkupOfflineServico } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class MarkupOfflineServicoService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number, markupespecial?: boolean): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

      if (!markupespecial) {
        if (param.length > 0)
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/list/${param.trim()}/${false}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
        else
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
      }
      else {
        if (param.length > 0)
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/list/${param.trim()}/${markupespecial}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
        else
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/list/${null}/${markupespecial}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/${id}`);
    }

    salvar(markupOfflineServico: MarkupOfflineServico): Observable<any>{
      if (markupOfflineServico.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/${markupOfflineServico.Id}`, markupOfflineServico);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/`, markupOfflineServico);
      }

    }

    buscarPorOfflineServico(param: string, pagina: Number, offlineServicoId: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/listporofflineservico/${offlineServicoId}/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/MarkupOfflineServico/listporofflineservico/${offlineServicoId}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    // vinculaPerfilMarkup (id: number, perfilMarkupId : number):Observable<any> {
    // return this.http.post<any>( `${GERENCIAMENTO}/api/markup-perfilmarkup/vincular/${id}/${perfilMarkupId}`, {});
    // }
    //
    // desvincularPerfilMarkup (id: number, perfilMarkupId : number):Observable<any> {
    //   return this.http.post<any>( `${GERENCIAMENTO}/api/markup-perfilmarkup/desvincular/${id}/${perfilMarkupId}`, {});
    // }

  }
