import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { CampanhaEtapa } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA,API_RELATORIOS } from '../../app.config';

@Injectable()
export class CampanhaEtapaService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

        if (param.length > 0)
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/CampanhaEtapa/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
        else
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/CampanhaEtapa?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/CampanhaEtapa/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/CampanhaEtapa/${id}`);
    }

    salvar(campanhaetapa: CampanhaEtapa): Observable<any>{
      if (campanhaetapa.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/CampanhaEtapa/${campanhaetapa.Id}`, campanhaetapa);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/CampanhaEtapa/`, campanhaetapa);
      }

    }

    GetNoitesVendidas(AgenciaId: number): Observable<any>{
        return this.http.get<any>( `${API_RELATORIOS}/api/CampanhaEtapa/getnoitesvendidas/${AgenciaId}`);
    }

    GetNoitesVendidasPorEtapa(CampanhaEtapaId : number, AgenciaId: number): Observable<any>{
      // return this.http.get<any>( `${GERENCIAMENTO}/api/CampanhaEtapa/getnoitesvendidasporetapa/${CampanhaEtapaId}/${AgenciaId}`);
      return this.http.get<any>( `${API_RELATORIOS}/api/CampanhaEtapa/getnoitesvendidasporetapa/${CampanhaEtapaId}/${AgenciaId}`);
    }

  }
