import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API, GERENCIAMENTO, ITENSPORPAGINA } from "../../app.config";
import { Observable } from "rxjs/Observable";
import { PoliticaVoucher } from "../../model/gerenciamento/politicavoucher.model";


@Injectable()
export class PoliticaVoucherService {
    constructor(private http: HttpClient) { }

    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set("noloader", "t");
        return this.http.get<any>(
            `${GERENCIAMENTO}/api/politicavoucher/list?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&Parametro=${param}`,
            { headers: headers }
        );

    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/politicavoucher/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${API}/api/politicavoucher/${id}`);
    }

    salvar(politicaVoucher: PoliticaVoucher): Observable<any> {
        if (politicaVoucher.Id > 0) {
            return this.http.put<any>(`${API}/api/politicavoucher/${politicaVoucher.Id}`, politicaVoucher);
        } else {
            return this.http.post<any>(`${API}/api/politicavoucher`, politicaVoucher);
        }
    }
}