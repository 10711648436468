import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { PacoteDestino } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class PacoteDestinoService {

    constructor(private http: HttpClient) { }
    buscarPorParametro(param: string, pagina: number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        if (param.length > 0) {
            return this.http.get<any>(`${GERENCIAMENTO}/api/PacoteDestino/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
        else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/PacoteDestino/?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
        }
    }

    listDestinos(): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
            return this.http.get<any>(`${GERENCIAMENTO}/api/PacoteDestino/Select`, { headers: headers });
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}api/PacoteDestino/${id}`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/PacoteDestino/remover/${id}`);
    }

    salvar(PacoteDestino: PacoteDestino): Observable<any> {
        if (PacoteDestino.Id > 0) {
            return this.http.put<any>(`${GERENCIAMENTO}/api/PacoteDestino/${PacoteDestino.Id}`, PacoteDestino);
        }
        else {
            return this.http.post<any>(`${GERENCIAMENTO}/api/PacoteDestino/`, PacoteDestino);
        }
    }

    // removerPacoteReserva(ReservaId: Number): Observable<any> {
    //     return this.http.delete<any>(`${GERENCIAMENTO}/api/PacoteDestino/Reserva/${ReservaId}`);
    // }
    // listaPacotesPagos(id: number): Observable<any> {
    //     return this.http.get<any>(
    //         `${GERENCIAMENTO}/api/pacote/pagos/${id}`
    //     );
    // }
    // buscarPacoteTipoPorParametro(param: string, pagina: Number): Observable<any> {
    //     const headers = new HttpHeaders().set('noloader', 't');
    //     if (param.length > 0) {
    //         return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/tipos/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    //     }
    //     else {
    //         return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/tipos?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    //     }

    // }

    // salvarTipo(PacoteTipo: PacoteTipo): Observable<any> {
    //     if (PacoteTipo.Id > 0) {
    //         return this.http.put<any>(`${GERENCIAMENTO}api/pacotetipo/${PacoteTipo.Id}`, PacoteTipo);
    //     }
    //     else {
    //         return this.http.post<any>(`${GERENCIAMENTO}api/pacotetipo`, PacoteTipo);
    //     }

    // }

    // removerTipo(id: Number): Observable<any> {
    //     return this.http.delete<any>(`${GERENCIAMENTO}/api/PacoteTipo/remover/${id}`);
    // }

    // buscarTipoPorId(id: number): Observable<any> {
    //     return this.http.get<any>(`${GERENCIAMENTO}api/pacote/tipos/update/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    // }

    // uploadImagemPrincipal(arquivo: any, id: number): Observable<any> {

    //     const url = `${GERENCIAMENTO}/api/pacote/imagemprincipal/${id}/`;
    //     const headers = new HttpHeaders().append('noloader', 'noloader');
    //     const formData = new FormData();
    //     formData.append('Arquivo', arquivo);

    //     return this.http.post(url, formData, { headers: headers });
    // }

    // uploadImagem(arquivo: any, id: number): Observable<any> {

    //     const url = `${GERENCIAMENTO}/api/pacote/imagem/${id}/`;

    //     const headers = new HttpHeaders().append('noloader', 'noloader');
    //     const formData = new FormData();
    //     formData.append('Arquivo', arquivo);

    //     return this.http.post(url, formData, { headers: headers });

    // }

    // removerImagem(id: Number): Observable<any> {
    //     return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote/imagem/remover/${id}`);
    // }


    // vincularServicoOffline(id: number, OfflineServicoId: number): Observable<any> {
    //     return this.http.post<any>(`${GERENCIAMENTO}/api/pacote-offlineservico/vincular/${id}/${OfflineServicoId}`, {});
    // }

    // desvincularServicoOffline(id: number, OfflineServicoId: number): Observable<any> {
    //     return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote-offlineservico/desvincular/${id}/${OfflineServicoId}`, {});
    // }

    // relPacotes(checkInInicial: any, checkInFinal: any, pacote: any, referencia: any): Observable<any> {
    //     return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/pacotesvendidos`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal, Pacote: pacote, Referencia: referencia }, { headers: new HttpHeaders().set('noloader', 'true') });
    // }

    // //Buscador de pacotes
    // buscaPacotesDisney(AgenciaId: number): Observable<any> {
    //     return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/disney/${AgenciaId}`);
    // }

    // buscaPacotesDisneyPorId(id: number, AgenciaId: number): Observable<any> {
    //     return this.http.get<any>(`${GERENCIAMENTO}/api/pacote/disney/${id}/${AgenciaId}`);
    // }

    // gerarOrcamentoDisney(pacote: any, AgenciaId: number): Observable<any> {
    //     return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/disney/gerarOrcamento/${AgenciaId}`, pacote);
    // }

    // reservarPacoteDisney(pacotes: any[]): Observable<any> {
    //     return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/disney/reservar`, pacotes);
    // }

    // cancelarPacoteDisney(pacotes: any[]): Observable<any> {
    //     return this.http.post<any>(`${GERENCIAMENTO}/api/pacote/disney/cancelar`, pacotes);
    // }

    // vincularTipo(id: number, PacoteTipoId: number): Observable<any> {
    //     return this.http.post<any>(`${GERENCIAMENTO}/api/pacote-tipo/vincular/${id}/${PacoteTipoId}`, {});
    // }

    // desvinculaTipo(id: number, PacoteTipoId: number): Observable<any> {
    //     return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote-tipo/desvincular/${id}/${PacoteTipoId}`, {});
    // }

    // vinculaAgencia(id: number, agenciaId: number): Observable<any> {
    //     return this.http.post<any>(`${GERENCIAMENTO}/api/pacote-agencia/vincular/${id}/${agenciaId}`, {});
    // }

    // desvincularAgencia(id: number, agenciaId: number): Observable<any> {
    //     return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote-agencia/desvincular/${id}/${agenciaId}`, {});
    // }

    // vincularConsultor(id: number, consultorId: number): Observable<any> {
    //     return this.http.post<any>(`${GERENCIAMENTO}/api/pacote-consultor/vincular/${id}/${consultorId}`, {});
    // }

    // desvincularConsultor(id: number, consultorId: number): Observable<any> {
    //     return this.http.delete<any>(`${GERENCIAMENTO}/api/pacote-consultor/desvincular/${id}/${consultorId}`, {});
    // }
}
