import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API } from '../app.config';

@Injectable()
export class Util {
    constructor(private translate: TranslateService) { }

    static pathImagemApi(nome: string): string {
        if (nome == null || nome == undefined || nome.length == 0) {
            return `/assets/img/notfound.jpg`;
        } else {
            return `${API}${nome}`;
        }

    }

    fazerTraducao(texto: string): string {
        try {
            return this.translate.instant(texto);
        } catch (e) {
            console.log(e);
            return texto;
        }
    }

    static sessionSet(key, value, expirationInMin = 10) {
        let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin));
        let newValue = {
            value: value,
            expirationDate: expirationDate.toISOString()
        };
        window.localStorage.setItem(key, JSON.stringify(newValue));
    }

    static sessionGet(key) {
        let stringValue = window.localStorage.getItem(key);
        if (stringValue !== null) {
            let object = JSON.parse(stringValue);
            let expirationDate = new Date(object.expirationDate);
            if (expirationDate > new Date()) {
                return object.value;
            } else {
                window.localStorage.removeItem(key);
            };
        }
        return null;
    }
}