export class Banner {
  public Id: number;
  public Nome: string;
  public Local: string;
  public Link: string;
  public ImagemURL: string;
  public ExtraInfo: string;
  public Deletado: boolean;
  public DeletadoPor: string;
  public DeletadoEm: string;

  constructor(){
    this.Local = "";
  }
}
