import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { PacoteHotel } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class PacoteHotelService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>
    {
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0)
      {
        return this.http.get<any>( `${GERENCIAMENTO}/api/PacoteHotel/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
      else
      {
        return this.http.get<any>( `${GERENCIAMENTO}/api/PacoteHotel?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>
    {
        return this.http.get<any>( `${GERENCIAMENTO}/api/PacoteHotel/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>
    {
      return this.http.delete<any>( `${GERENCIAMENTO}/api/PacoteHotel/${id}`);
    }

    salvar(PacoteHotel: PacoteHotel): Observable<any>{
      if (PacoteHotel.Id > 0)
      {
        return this.http.put<any>( `${GERENCIAMENTO}/api/PacoteHotel/${PacoteHotel.Id}`, PacoteHotel);
      }
      else
      {
        return this.http.post<any>( `${GERENCIAMENTO}/api/PacoteHotel/`, PacoteHotel);
      }

    }

  }
