import { Aeroporto } from '../model';
import { WebService } from '../model';
import { LojasCarro } from '../model';

export class CidadesWebServices {
  Id: number;
  Cidade?: any = {Nome : ''};
  CidadeId: number;
  IdCidadeFornecedor: string;
  WebService?: any = {Descricao : ''};
  WebServiceId: number;
  Ativo: boolean = true;
}

export class Cidade {
    Nome: string  = '';
    Estado: Estado = new Estado();
    IdErp?: any;
    CodigoIbge? : number;
    CodigoGIATA: string;
    MostraOpcaoBusca: boolean;
    CidadeVinculadaId?: any;
    EstadoId: number;
    CidadeVinculada?: any = {Nome : ''};
    CidadesVinculadas?: any;
    CidadesWebServices?: CidadesWebServices[];
    Aeroportos?: Aeroporto[];
    LojasCarros?: LojasCarro[];
    CidadePoligono?: CidadePoligono[];
    Bairros?: any;
    Id: number;
    Erros?: any;
    display_field: string;
    public Imagem: string;
    public ImagemApresentacao: any;
    public Alteracoes : any;
    zone : number[];
    // Comunicado: string;
}

export class CidadePoligono {
  Id: number;
  CidadeLatitude: any;
  CidadeLongitude: any;
  CidadeNome: any;
  Regiao: Array<Regiao>;
  CidadePoligonoRegiaoId: number;
}
export class Regiao {
  Pontos: Array<Pontos>;
}
export class Pontos {
  Latitude: number;
  Longitude: number;
}

export class CidadePoligonoRegiao {
  Id: number;
  Descricao: string;
}

export class Estado {
  Id: number;
  Cidades: Cidade[];
  Nome: string;
  Pais?: Pais = new Pais();
  Iata: any;
}

export class Pais {
  Id: number;
  Estados: Estado[];
  Nome: string ='';
  Iata: any;
  display_field: string;

}
