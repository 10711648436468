import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { API, EMPRESAS, ITENSPORPAGINA, GERENCIAMENTO } from '../../app.config';
import { LoginService } from '../login.service';
import { IntranetArquivo } from '../../model/intranet/arquivo.model';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { JsonConverter } from 'json2typescript';

@Injectable()

export class IntranetArquivoService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService
  ) { }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/intranet/anexo/${id}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/intranet/anexo/${id}`);
  }

  uploadArquivo(arquivo: any = null, objetoClass: IntranetArquivo):  Observable<any> {
    let transicao = JSON.stringify(objetoClass);
    const url = `${API}/api/intranet/anexo/upload`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);
    formData.append('Dados', transicao);
    return this.http.post(url, formData, { headers: headers });
  }

  editar(objetoClass: IntranetArquivo): Observable<any> {
    
    return this.http.put<any>(`${API}/api/intranet/anexo/edit/${objetoClass.Id}`, objetoClass);
  }

  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/intranet/anexo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/intranet/anexo/list?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }

  buscarAnexoConteudo(conteudoId: number, pagina: Number) {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/intranet/anexo/list/conteudo/${conteudoId}?Pagina=${pagina}&ItensPorPagina=1000`, { headers: headers });
  }

  removerArquivo(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/intranet/anexo/remover/${id}`);
  }

}
