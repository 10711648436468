import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { API, EMPRESAS, ITENSPORPAGINA } from '../../app.config';
import { LoginService } from '../login.service';
import { IntranetGrupo } from '../../model/intranet/grupo.model';

@Injectable()

export class IntranetGrupoService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService
  ) { }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/intranet/grupo/${id}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/intranet/grupo/${id}`);
  }

  salvar(objetoClass: IntranetGrupo): Observable<any> {
    if (objetoClass.Id > 0) {
      return this.http.put<any>(`${API}/api/intranet/grupo/${objetoClass.Id}`, objetoClass);
    } else {
      return this.http.post<any>(`${API}/api/intranet/grupo`, objetoClass);
    }
  }

  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/intranet/grupo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/intranet/grupo/list?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }

  buscarTodosGrupos(){
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/intranet/grupo/todos` , { headers: headers });
  }

  buscarTodosConteudo() {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/intranet/grupo/conteudo/list`, { headers: headers })
  }
}
