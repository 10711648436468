import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';


import { TaxasExtrasAereo } from '../../model';

import { API, ITENSPORPAGINA } from '../../app.config';


@Injectable()
export class TaxasExtrasService{
  constructor(
    private http: HttpClient,){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

      if (param.length > 0)
      {
        return this.http.get<any>( `${API}/api/taxasExtrasService/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
      else
      {
        return this.http.get<any>( `${API}/api/taxasExtrasService?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>
    {
        return this.http.get<any>( `${API}/api/taxasExtrasService/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>
    {
      return this.http.delete<any>( `${API}/api/taxasExtrasService/${id}`);
    }

    salvar(taxasExtrasService: TaxasExtrasAereo): Observable<any>{
      if (taxasExtrasService.Id > 0)
      {
        return this.http.put<any>( `${API}/api/taxasExtrasService/${taxasExtrasService.Id}`, taxasExtrasService);
      }
      else
      {
        return this.http.post<any>( `${API}/api/taxasExtrasService/`, taxasExtrasService);
      }

    }


  }
