import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API, GERENCIAMENTO, ITENSPORPAGINA } from "../../app.config";
import { Observable } from "rxjs/Observable";
import { Comunicados } from "../../model/gerenciamento/comunicados.model";

@Injectable()
export class ComunicadosService {
    constructor(private http: HttpClient) { }

    buscarPorParametro(param: any, cidade: string, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set("noloader", "t");
        return this.http.get<any>(
            `${GERENCIAMENTO}/api/comunicados/list?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&Parametro=${param}&Cidade=${cidade}`,
            { headers: headers }
        );
    }

    // buscarPorParametro(parametro: string, cidade: string, pagina: number): Observable<any> {
    //     const headers = new HttpHeaders().set("noloader", "t");
    //     let url = `${GERENCIAMENTO}/api/comunicados/list?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`;
    
    //     if (parametro) {
    //         url += `&Parametro=${parametro}`;
    //     }
    
    //     if (cidade) {
    //         url += `&Cidade=${cidade}`;
    //     }
    
    //     return this.http.get<any>(url, { headers: headers });
    // }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/comunicados/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${API}/api/comunicados/${id}`);
    }

    salvar(comunicados: Comunicados): Observable<any> {
        if (comunicados.Id > 0) {
            return this.http.put<any>(`${API}/api/comunicados/${comunicados.Id}`, comunicados);
        } else {
            return this.http.post<any>(`${API}/api/comunicados`, comunicados);
        }
    }
}