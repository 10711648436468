import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';


import { Cidade } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class RestelService{

  constructor(private http: HttpClient){}

    importar(cidade: Cidade): Observable<any>{
        return this.http.post<any>( `${GERENCIAMENTO}/api/Restel/`, cidade);
    }

  }
