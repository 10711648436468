import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { WebService, CidadesWebServices } from '../../model';
import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class WsService {

  constructor(private http: HttpClient) { }


  buscarPorParametro(param: string, pagina: Number, tipo: string = '', sigla: string = ''): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param && param.length > 0) {
      return this.http.get<any>(`${GERENCIAMENTO}/api/webservice/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&tipo=${tipo}&sigla=${sigla}`, { headers: headers });
    } else {
      return this.http.get<any>(`${GERENCIAMENTO}/api/webservice?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&tipo=${tipo}&sigla=${sigla}`, { headers: headers });
    }
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/webservice/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/webservice/${id}`);
  }

  salvar(webService: WebService): Observable<any> {
    if (webService.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/webservice/${webService.Id}`, webService);
    } else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/webservice/`, webService);
    }

  }

  vinculaCidadeIntegrador(cidadesWebService: CidadesWebServices[]): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/webservice-integrador/vincular`, cidadesWebService);
  }

  desvincularCidadeIntegrador(cidadesWebService: CidadesWebServices[]): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/webservice-integrador/desvincular`, cidadesWebService);
  }

  atualizarCidadeIntegrador(cidadesWebService: CidadesWebServices): Observable<any> {
    return this.http.put<any>(`${GERENCIAMENTO}/api/cidade-integrador/atualizar`, cidadesWebService);
  }

  buscarCidadesPorWebService(WebServiceId: Number, param: String, pagina: Number) {
    console.log('Parametro de busca de cidade', param);
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${GERENCIAMENTO}/api/webservice/list/cidade-por-webservice/${WebServiceId}/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${GERENCIAMENTO}/api/webservice/list/cidade-por-webservice/${WebServiceId}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }

  buscarCidadeWsPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${GERENCIAMENTO}/api/webservice/list/cidadewebservice/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    } else {
      return this.http.get<any>(`${GERENCIAMENTO}/api/webservice/list/cidadewebservice?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: headers });
    }
  }

  vincularEmpresa(id: number, EmpresaId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/webservice-empresa/vincular/${id}/${EmpresaId}`, {});
  }

  desvincularEmpresa(id: number, EmpresaId: number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/webservice-empresa/desvincular/${id}/${EmpresaId}`, {});
  }



  buscarWebservicesGenius(): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/Webservice/ListaWebserviceGenius`);
  }

  verificarStatusReceptivos(): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/Webservice/VerificarStatusReceptivos`, { headers: new HttpHeaders().set('noloader', 't') });
  }

  refazerLoginReceptivos(): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/Webservice/RefazerLoginReceptivos`);
  }
}
