import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';


import { PerfilUsuario } from '../../model';

import { API, ITENSPORPAGINA , CLIENT_ID} from '../../app.config';


@Injectable()
export class PerfilUsuarioService{
  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

      if (param.length > 0){
        return this.http.get<any>( `${API}/api/perfilusuario/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${API}/api/perfilusuario?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${API}/api/perfilusuario/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${API}/api/perfilusuario/${id}`);
    }

    salvar(perfilUsuario: PerfilUsuario): Observable<any>{
      if (perfilUsuario.Id > 0){
        return this.http.put<any>( `${API}/api/perfilusuario/${perfilUsuario.Id}`, perfilUsuario);
      }else{
        return this.http.post<any>( `${API}/api/perfilusuario/`, perfilUsuario);
      }

    }

    // PERMISSOES
    todasPermissoes(): Observable<any>{
        return this.http.get<any>( `${API}/api/permissao/todas/1`);
    }

    vincularPermissao(perfilId: number, permissaoId:number): Observable<any>{
        return this.http.get<any>( `${API}/api/perfilusuario/${perfilId}/vincular/${permissaoId}`);
    }

    desvincularPermissao(perfilId: number, permissaoId:number): Observable<any>{
        return this.http.get<any>( `${API}/api/perfilusuario/${perfilId}/desvincular/${permissaoId}`);
    }

    // RELATORIOS
    todosRelatorios(): Observable<any>{
        return this.http.get<any>( `${API}/api/relatorio/todas/1`);
    }

    vincularRelatorio(perfilId: number, relatorioId:number): Observable<any>{
        return this.http.get<any>( `${API}/api/perfilusuario/${perfilId}/vincular-relatorio/${relatorioId}`);
    }

    desvincularRelatorio(perfilId: number, relatorioId:number): Observable<any>{
        return this.http.get<any>( `${API}/api/perfilusuario/${perfilId}/desvincular-relatorio/${relatorioId}`);
    }

  }
