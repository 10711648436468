import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { OfflineApartamentoTarifa } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class TarifacategoriaOfflineApartamentoService {

  constructor(private http: HttpClient) { }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/OfflineApartamentoTarifa/${id}`);
  }

  salvar(offlineCategoriaApartamentoTarifa: OfflineApartamentoTarifa): Observable<any> {
    console.log(offlineCategoriaApartamentoTarifa);
    if (offlineCategoriaApartamentoTarifa.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/OfflineApartamentoTarifa/${offlineCategoriaApartamentoTarifa.Id}`, offlineCategoriaApartamentoTarifa);
    } else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineApartamentoTarifa/`, offlineCategoriaApartamentoTarifa);
    }

  }

  buscarPorParametro(param: string, pagina: Number, idcategoria: Number, datainicial: any = "", datafinal: any = ""): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${GERENCIAMENTO}/api/OfflineApartamentoTarifa/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&IdCategoria=${idcategoria}&DataInicial=${datainicial}&DataFinal=${datafinal}`, { headers: headers });
    } else {
      return this.http.get<any>(`${GERENCIAMENTO}/api/OfflineApartamentoTarifa?&Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&IdCategoria=${idcategoria}&DataInicial=${datainicial}&DataFinal=${datafinal}`, { headers: headers });
    }
  }

  atualizarValidade(idcategoria: Number, datainicial: string, datafinal: string, validadede: string, validadeate: string): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineApartamentoTarifa/atualizarvalidade`, { idcategoria: idcategoria, datainicial: datainicial, datafinal: datafinal, validadede: validadede, validadeate: validadeate });

  }
  atualizarCheck(idcategoria: Number, datainicial: string, datafinal: string, validadede: string, validadeate: string): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineApartamentoTarifa/atualizarcheck`, { idcategoria: idcategoria, datainicial: datainicial, datafinal: datafinal, validadede: validadede, validadeate: validadeate });
  }
  salvartarifaunica(offlineCategoriaApartamentoTarifa: OfflineApartamentoTarifa): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineApartamentoTarifa/salvartarifaunica`, offlineCategoriaApartamentoTarifa);
  }


}
