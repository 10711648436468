import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { Moeda } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class MoedaService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

        if (param.length > 0)
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/Moeda/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
        else
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/Moeda?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/Moeda/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/Moeda/${id}`);
    }

    salvar(moeda: Moeda): Observable<any>{
      if (moeda.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/Moeda/${moeda.Id}`, moeda);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/Moeda/`, moeda);
      }

    }

  }
