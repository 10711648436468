import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { API } from "../../app.config";
import { PadraoPensaoFornecedor, Pensoes } from "../../model/cadastro/pensoes.model";

@Injectable()
export class PensoesService {
    constructor(
        private http: HttpClient,
    ) { }

    // Get
    buscarTodos() {
        return this.http.get<any>(`${API}/api/pensoes`);
    }

    buscarPorId(id: number) {
        return this.http.get<any>(`${API}/api/pensoes/${id}`);
    }

    buscarPorParametros(parametro: string) {
        const headers = new HttpHeaders().set('noloader', 't');

        return this.http.get<any>(`${API}/api/pensoes/buscarPorParametros/${parametro}`, { headers: headers });
    }

    // Post
    criar(pensoes: Pensoes) {
        return this.http.post<any>(`${API}/api/pensoes/criar`, pensoes);
    }

    // Put
    alterar(id: number, pensoes: Pensoes) {
        return this.http.put<any>(`${API}/api/pensoes/alterar/${id}`, pensoes);
    }
}

@Injectable()
export class PadraoPensaoFornecedorService {
    constructor(
        private http: HttpClient,
    ) { }

    // Get
    buscarTodos() {
        return this.http.get<any>(`${API}/api/padraoPensaoFornecedor/buscarTodos`);
    }

    buscarPorParametros(parametro: string) {
        return this.http.get<any>(`${API}/api/padraoPensaoFornecedor/buscarTodos/${parametro}`);
    }

    buscarPorId(id: number) {
        return this.http.get<any>(`${API}/api/padraoPensaoFornecedor/buscarPorId/${id}`);
    }

    // Post
    criar(padraoPensaoFornecedor: PadraoPensaoFornecedor) {
        return this.http.post<any>(`${API}/api/padraoPensaoFornecedor/criar`, padraoPensaoFornecedor);
    }

    // Put
    alterar(id: number, padraoPensaoFornecedor: PadraoPensaoFornecedor) {
        return this.http.put<any>(`${API}/api/padraoPensaoFornecedor/alterar/${id}`, padraoPensaoFornecedor);
    }
}