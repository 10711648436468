import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { IntranetCategoria } from  '../../model/intranet/categoria.model';
import { EMPRESAS, ITENSPORPAGINA } from '../../app.config';
import { LoginService } from '../login.service';

@Injectable()

export class IntranetCategoriaService{

  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService
  ){}

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${EMPRESAS}/api/intranet/categoria/${id}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${EMPRESAS}/api/intranet/categoria/${id}`);
    }

    salvar(objetoClass: IntranetCategoria): Observable<any>{
      if (objetoClass.Id > 0){
        return this.http.put<any>( `${EMPRESAS}/api/intranet/categoria/${objetoClass.Id}`, objetoClass);
      } else {
        return this.http.post<any>(`${EMPRESAS}/api/intranet/categoria`, objetoClass);
      }
    }

    VincularGrupoCategoria(categoriaId, grupoId){
      return this.http.get<any>( `${EMPRESAS}/api/categoria/${categoriaId}/vincularGrupo/${grupoId}`);
    }

    DesvincularGrupoCategoria(categoriaId, grupoId){
      return this.http.get<any>( `${EMPRESAS}/api/categoria/${categoriaId}/desvincularGrupo/${grupoId}`);
    }

    buscarPorParametro(param: string, pagina: Number, trazerTodos: boolean = false): Observable<any> {
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0) {
        return this.http.get<any>(`${EMPRESAS}/api/intranet/categoria/ListaCategoriaPai/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${trazerTodos ? 1000 : ITENSPORPAGINA}`, { headers: headers });
      } else {
        return this.http.get<any>(`${EMPRESAS}/api/intranet/categoria/ListaCategoriaPai?Pagina=${pagina}&ItensPorPagina=${trazerTodos ? 1000 : ITENSPORPAGINA}`, { headers: headers });
      }
    }

    buscarPorParametroTodos(param: string, pagina: Number): Observable<any> {
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0) {
        return this.http.get<any>(`${EMPRESAS}/api/intranet/categoria/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=1000`, { headers: headers });
      } else {
        return this.http.get<any>(`${EMPRESAS}/api/intranet/categoria/list?Pagina=${pagina}&ItensPorPagina=1000`, { headers: headers });
      }
    }
  }
