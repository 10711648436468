import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs-compat";
// import { Router } from "@angular/router";

// import { LoginService } from "../login.service";
import { API } from "../../app.config";
import { VoucherIntranet } from "../../model/intranet/voucherIntranet.model";

@Injectable()
export class IntranetVoucherService {

    constructor(
        private http: HttpClient,
    ) {}

    buscarPorId(id: number) {
        return this.http.get<any>(`${API}/api/voucherGrupo/listarVoucherGrupo/${id}`);
    }

    buscarTodos() {
        return this.http.get<any>(`${API}/api/voucherGrupo/listarTodosVoucherGrupo`);
    }

    salvar(voucher: VoucherIntranet) {
        return this.http.post<any>(`${API}/api/voucherGrupo/criarVoucherGrupo`, voucher);
    }

    editar(voucher: VoucherIntranet, id: number) {
        return this.http.put<any>(`${API}/api/voucherGrupo/editarVoucherGrupo/${id}`, voucher);
    }

    gerarVoucher(id: number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');
        return this.http.get<any>(`${API}/api/voucherGrupo/gerarVoucherGrupo/${id}`, { headers: headers, responseType: 'blob' as 'json'})
        .map((res) => {
            return new Blob([res], { type: 'application/pdf' })
        });
    }

    exibirVoucherHtml(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.http.get<any>(`${API}/api/voucherGrupo/gerarVoucherGrupo/${id}?html=true`, { headers, responseType: 'text' as 'json' });
    }

    enviarVoucherEmail(id: number, email: string) {
        return this.http.get<any>(`${API}/api/voucherGrupo/enviarVoucherGrupoEmail/${id}/${email}`);
    }

}